
import { allScopes, alwaysDisplayed } from '../shared';
import { billingAccountLinkPermissions } from '../client-center/billing-payments';


export const buildCustomReportsPage = {
  id: 'custom-reports-build-custom-report',
  name: _TL_('Build your own report'),
  route: 'customreports/create',
  fixedRoute: 'customreports/create',
  scope: allScopes,
  campaignTypes: alwaysDisplayed,
  checkPermission: permissions => permissions.dynamic.CustomReportBuilder,
};

export const editCustomReportPage = {
  id: 'custom-reports-edit-report',
  name: _TL_('Edit report'),
  title: _TL_('View and edit a report'),
  route: 'customreports/edit/:TaskItemId',
  scope: allScopes,
  campaignTypes: alwaysDisplayed,
  searchable: false,
  accountLinkPermissions: billingAccountLinkPermissions,
  checkPermission: permissions => permissions.dynamic.CustomReportBuilder,
};
