import { performance } from './performance';
import { assetsExtensions } from './assets-extensions';
import { productAds } from './product-ads';
import { changeHistory } from './change-history';
import { targetingPlacements } from './targeting-placements';
import { billingAndBudget } from './billing-and-budget';
import { advancedInsights } from './advanced-insights';
import { dynamicSearch } from './dynamic-search-ads';
import { labels } from './labels';
import { hotel, property } from './property-ads';
import { appAds } from './app-ads';
import { competitiveVisibility } from './competitive-visibility';
import { goalsConversions } from './goals-conversions';
import { verticalAds } from './vertical-ads';

export const defaultReports = [
  performance,
  competitiveVisibility,
  goalsConversions,
  assetsExtensions,
  targetingPlacements,
  productAds,
  dynamicSearch,
  hotel,
  property,
  verticalAds,
  appAds,
  advancedInsights,
  billingAndBudget,
  changeHistory,
  labels,
];
