import _ from 'underscore';
import { CampaignType, AdGroupType, Scope } from '../shared';

const campaignTypes = [
  CampaignType.Default,
  CampaignType.Shopping,
];
const adGroupTypes = [AdGroupType.SearchStandard, AdGroupType.SearchDynamic];

const apexViewName = 'extensions';

const supportAll = ['action', 'app', 'callout', 'image', 'price', 'review', 'sitelink', 'structuredsnippet', 'promotion', 'filterlink', 'flyer', 'video', 'businessinfo'];
const supportLevel = {
  account: [...supportAll, 'location', 'leadform'],
  campaign: [...supportAll, 'location', 'leadform'],
  adgroup: supportAll,
};

const allowedCampaignTypes = (campaignType, permissions, overrideKeys, adGroupLevel) => {
  let keys = overrideKeys || [
    CampaignType.Default,
    CampaignType.Shopping,
    CampaignType.PerformanceMax,
  ];

  if (permissions.dynamic.UnifiedAppAds) {
    keys.push(CampaignType.App);
  }

  if (adGroupLevel) {
    keys = _.without(keys, CampaignType.PerformanceMax, CampaignType.App);
  }

  const result = _.some(keys, type => (type === campaignType) ||
  (type & campaignType)); // eslint-disable-line no-bitwise
  return result;
};

const automatedExtensionsChildren = [
  {
    id: 'automated-extensions',
    name: _TL_('Automated extensions'),
    route: 'extensions/automated',
    campaignTypes,
    adGroupTypes,
  },
  {
    id: 'image-annoation',
    name: _TL_('Image annotation'),
    route: 'extensions/imageannotation',
    searchAlias: _TL_('Automated extension - Image annotation'),
    campaignTypes: [
      CampaignType.Default,
      CampaignType.DynamicSearchAds,
    ],
    adGroupTypes,
  },
];

const extensionChildrens = [
  {
    id: 'action',
    name: _TL_('Action extension'),
    route: 'extensions/action',
    searchAlias: _TL_('Ad extension - Action'),
    campaignTypes: allowedCampaignTypes,
    adGroupTypes,
    apexViewName,
  }, {
    id: 'app',
    name: _TL_('App extension'),
    route: 'extensions/app',
    searchAlias: _TL_('Ad extension - App'),
    campaignTypes: (type, permissions) => allowedCampaignTypes(type, permissions, [
      CampaignType.Default,
      CampaignType.Shopping,
      CampaignType.PerformanceMax,
    ]),
    adGroupTypes,
    apexViewName,
  }, {
    id: 'call',
    name: _TL_('Call extension'),
    route: 'extensions/call',
    searchAlias: _TL_('Ad extension - Call'),
    campaignTypes: allowedCampaignTypes,
    adGroupTypes,
    apexViewName,
  }, {
    id: 'callout',
    name: _TL_('Callout extension'),
    route: 'extensions/callout',
    searchAlias: _TL_('Ad extension - Callout'),
    campaignTypes: (type, permissions) => allowedCampaignTypes(type, permissions, [
      CampaignType.Default,
      CampaignType.Shopping,
      CampaignType.Hotel,
      CampaignType.PerformanceMax,
    ]),
    adGroupTypes,
    apexViewName,
  }, {
    id: 'image',
    name: _TL_('Image extension'),
    route: 'extensions/image',
    searchAlias: _TL_('Ad extension - Image'),
    campaignTypes: allowedCampaignTypes,
    adGroupTypes,
    apexViewName,
  }, {
    id: 'location',
    name: _TL_('Location extension'),
    route: 'extensions/location',
    searchAlias: _TL_('Ad extension - Location'),
    campaignTypes: allowedCampaignTypes,
    adGroupTypes,
    apexViewName,
  }, {
    id: 'price',
    name: _TL_('Price extension'),
    route: 'extensions/price',
    searchAlias: _TL_('Ad extension - Price'),
    campaignTypes: allowedCampaignTypes,
    adGroupTypes,
    apexViewName,
  }, {
    id: 'review',
    name: _TL_('Review extension'),
    route: 'extensions/review',
    searchAlias: _TL_('Ad extension - Review'),
    campaignTypes: allowedCampaignTypes,
    adGroupTypes,
    apexViewName,
  }, {
    id: 'sitelink',
    name: _TL_('Sitelink extension'),
    route: 'extensions/sitelink',
    searchAlias: _TL_('Ad extension - Sitelink'),
    campaignTypes: allowedCampaignTypes,
    adGroupTypes,
    apexViewName,
  }, {
    id: 'structuredsnippet',
    name: _TL_('Structured snippet extension'),
    route: 'extensions/structuredsnippet',
    searchAlias: _TL_('Ad extension - Structured snippet'),
    campaignTypes: allowedCampaignTypes,
    apexViewName,
  }, {
    id: 'promotion',
    name: _TL_('Promotion extension'),
    route: 'extensions/promotion',
    searchAlias: _TL_('Ad extension - Promotion'),
    campaignTypes: allowedCampaignTypes,
    adGroupTypes,
    apexViewName,
  },
  {
    id: 'filterlink',
    name: _TL_('Filter link extension'),
    route: 'extensions/filterlink',
    searchAlias: _TL_('Ad extension - Filter'),
    campaignTypes: allowedCampaignTypes,
    adGroupTypes,
    apexViewName,
  },
  {
    id: 'flyer',
    name: _TL_('Flyer extension'),
    route: 'extensions/flyer',
    searchAlias: _TL_('Ad extension - Flyer'),
    campaignTypes: allowedCampaignTypes,
    adGroupTypes,
    apexViewName,
  },
  {
    id: 'video',
    name: _TL_('Video extension'),
    route: 'extensions/video',
    searchAlias: _TL_('Ad extension - Video'),
    campaignTypes: allowedCampaignTypes,
    adGroupTypes,
    apexViewName,
  },
  {
    id: 'leadform',
    name: _TL_('Lead Form extension'),
    route: 'extensions/leadform',
    searchAlias: _TL_('Ad extension - Lead Form'),
    campaignTypes: allowedCampaignTypes,
    adGroupTypes,
    apexViewName,
  },
  {
    id: 'businessinfo',
    name: _TL_('Business Info extension'),
    route: 'extensions/businessinfo',
    searchAlias: _TL_('Ad extension - Business Info Form'),
    campaignTypes: allowedCampaignTypes,
    adGroupTypes,
    apexViewName,
  },
];

const extensionChildrensWithLevels = _.map(extensionChildrens, (child) => {
  const withLevel = [];
  _.each(supportLevel, (value, key) => {
    if (_.contains(value, child.id)) {
      let currentCampaignTypes = child.campaignTypes;

      // only for sitelink and callout we need hotel campaigns.
      if (key === 'adgroup' && (child.route !== 'extensions/sitelink' || child.route !== 'extensions/callout')) {
        currentCampaignTypes = (type, permissions) => allowedCampaignTypes(type, permissions, null, true);
      } else if (key === 'adgroup' && (child.route === 'extensions/sitelink' || child.route === 'extensions/callout')) {
        currentCampaignTypes = (type, permissions) => allowedCampaignTypes(type, permissions, [
          CampaignType.Default,
          CampaignType.Shopping,
          CampaignType.Hotel,
          CampaignType.PerformanceMax,
        ], true);
      }

      withLevel.push({
        id: `${child.id}-${key}`,
        name: _TL_(''), // global search duplicate names
        route: `${child.route}/association/${key}`,
        campaignTypes: currentCampaignTypes,
        apexViewName,
      });
    }
  });

  return withLevel;
});

export const adsAndExtensions = {
  id: 'ads-and-extensions',
  name: _TL_('Ads & extensions'),
  campaignTypes: (type, permissions) => {
    if (type === CampaignType.App && !permissions.dynamic.UnifiedAppAds) {
      return false;
    }

    return type !== CampaignType.None;
  },
  children: [
    {
      id: 'ads',
      name: _TL_('Ads'),
      route: 'ads',
      shortcut: 'g+a',
      campaignTypes: [CampaignType.Default, CampaignType.Shopping, CampaignType.Audience,
        CampaignType.DynamicSearchAds, CampaignType.Smart, CampaignType.Hotel, CampaignType.LinkedIn],
      adGroupTypes,
    }, {
      id: 'assets-report',
      name: _TL_('Assets'),
      route: 'assets-report',
      checkPermission: permissions => !permissions.dynamic.RSAAssetCampaignAdgroupLevel && (permissions.dynamic.RSAAssets || permissions.IsRSAAssetsEnabled),
      scope: [Scope.Account],
      campaignTypes: [CampaignType.Default],
    }, {
      id: 'extensions',
      name: _TL_('Extensions'),
      route: 'extensions/sitelink',
      campaignTypes: (type, permissions) => allowedCampaignTypes(type, permissions, [
        CampaignType.Default,
        CampaignType.Shopping,
        CampaignType.Hotel,
        CampaignType.PerformanceMax,
      ]),
      adGroupTypes,
      children: _.flatten([extensionChildrens, extensionChildrensWithLevels]),
      shortcut: 'g+x',
      apexViewName,
    }, {
      id: 'automated-extensions',
      name: _TL_('Automated extensions'),
      route: 'extensions/automated',
      campaignTypes,
      adGroupTypes,
      children: automatedExtensionsChildren,
    },
  ],
};
