import { available, scope } from './common';

export const goalsConversions = {
  id: 'custom-reports-category-goals-conversions',
  name: _TL_('Goals and Conversions'),
  scope,
  available,
  children: [
    {
      id: 'customreports-desturlreport',
      name: _TL_('Destination URL'),
      title: _TL_('See how your landing pages are performing.'),
      fixedRoute: 'customreports/create/desturlreport',
      route: 'customreports/create/desturlreport',
      searchAlias: _TL_('Destination URL report'),
      scope,
      available,
      apiKey: 'DestinationURLPerformanceReport',
      description: _TL_('To identify landing pages that met audience expectations, resulting in high click or conversion ratios.'),
    }, {
      id: 'customreports-goalsreport',
      name: _TL_('Goals'),
      title: _TL_('See data on how people behave on your website.'),
      fixedRoute: 'customreports/create/goalsreport',
      route: 'customreports/create/goalsreport',
      searchAlias: _TL_('Goals report'),
      scope,
      available,
      apiKey: 'GoalsAndFunnelsReport',
      description: _TL_('To understand which campaigns and keywords are leading users to complete a specific type of conversion action. Note: these reports only show data for text ads and do not include shopping, dynamic search ads and audience campaigns conversion data.'),
    }, {
      id: 'customreports-conversionsreport',
      name: _TL_('Conversions'),
      title: _TL_('See conversion data for your campaigns during a specific time period.'),
      fixedRoute: 'customreports/create/conversionsreport',
      route: 'customreports/create/conversionsreport',
      searchAlias: _TL_('Conversions report'),
      scope,
      available,
      apiKey: 'ConversionPerformanceReport',
      description: _TL_('To understand which campaigns and keywords are leading customers to complete conversion actions. Note: these reports only show data for text ads and do not include shopping, dynamic search ads and audience campaigns conversion data.'),
    }, {
      id: 'customreports-conversionmodelcomparereport',
      name: _TL_('Conversion model compare'),
      title: _TL_('See influence of conversion attribution models.'),
      fixedRoute: 'customreports/create/conversionmodelcomparereport',
      route: 'customreports/create/conversionmodelcomparereport',
      searchAlias: _TL_('Conversion model compare report'),
      scope,
      available,
      apiKey: 'ConversionModelCompareReport',
      description: _TL_('To discover how different conversion attribution models impact the valuation of your campaigns.'),
    },
  ],
};
