import { Scope } from '../shared';

export const fraudAppeal = {
  id: 'fraudAppealStandalone',
  name: _TL_('Fraud appeal'),
  children: [
    {
      id: 'fraudAppeal',
      name: _TL_('Fraud appeal'),
      route: 'FraudAppeal',
      scope: [Scope.Customer],
    },
    {
      id: 'fraudAppealAIV',
      name: _TL_('Fraud appeal - Account verification'),
      route: 'FraudAppeal/AccountIdentityVerification',
      scope: [Scope.Account],
    },
    {
      id: 'fraudAppealAIVWelcome',
      name: _TL_('Fraud appeal - Account verification welcome'),
      route: 'FraudAppeal/AccountIdentityVerificationWelcome',
      scope: [Scope.Account],
    },
  ],
};
