import _ from 'underscore';

import { AdjustmentReason, AdjustmentReasonString, CreditActions, DebitActions } from './constants/adjustments';

/**
 * Map adjustment reasons to localized name by new adjustment ids
 * @param {object[]} reasons List of reasons returned by domain data
 * @param {number} reasons[].id Adjustment reason id
 * @param {object} reasons[].value Adjustment reason value object
 * @param {number} reasons[].value.Id Adjustment reason id
 * @param {string} reasons[].value.Name Adjustment reason name
 * @param {object} i18n i18n
 * @returns {array} The mapped adjustment reason filtered by new adjustment ids
 */
export const mapNewAdjustmentReasons = (reasons, i18n) => {
  const unselectedReason = { id: AdjustmentReason.None, value: i18n.getString(AdjustmentReasonString[AdjustmentReason.None]) };
  const newAdjustmentReasonsFormatted = _.chain(reasons)
    .filter(reason => _.contains(_.values(AdjustmentReason), reason.id))
    .map(reason => ({
      id: reason.id,
      value: i18n.getString(AdjustmentReasonString[reason.id]),
    }))
    .value();

  return [unselectedReason, ...newAdjustmentReasonsFormatted];
};

export const isCreditAction = action => _.contains(CreditActions, parseInt(action, 10));

export const isDebitAction = action => _.contains(DebitActions, parseInt(action, 10));
