import { available, scope } from './common';
import { Scope } from '../../shared';

const accountLevelScopes = scope.filter(_scope => _scope !== Scope.Customer);

export const performance = {
  id: 'reports-v4-category-performance',
  name: _TL_('Performance'),
  title: _TL_('Performance reports give you a closer look at how your campaigns are performing, including details like CTR and impressions.'),
  fixedRoute: 'reports-v4/category/performance',
  route: 'reports-v4/category/performance',
  scope,
  available,
  children: [
    {
      id: 'reports-v4-accountreport',
      name: _TL_('Account'),
      title: _TL_('Understand account performance and identify trends.'),
      fixedRoute: 'reports-v4/create/accountreport',
      route: 'reports-v4/create/accountreport',
      searchAlias: _TL_('Account report'),
      scope,
      available,
      apiKey: 'AccountPerformanceReport',
      description: _TL_('To observe long-term account performance and trends.'),
    }, {
      id: 'reports-v4-campaignreport',
      name: _TL_('Campaign'),
      title: _TL_('See a snapshot of your account, and if there\'s anything to flag.'),
      fixedRoute: 'reports-v4/create/campaignreport',
      route: 'reports-v4/create/campaignreport',
      searchAlias: _TL_('Campaign report'),
      scope,
      available,
      apiKey: 'CampaignPerformanceReport',
      description: _TL_('To view high-level performance statistics and quality attributes for each campaign or account. This is also a quick way to flag any major campaign or account problems.'),
    }, {
      id: 'reports-v4-adgroupreport',
      name: _TL_('Ad group'),
      title: _TL_('See if your ad group is meeting your preset goals.'),
      fixedRoute: 'reports-v4/create/adgroupreport',
      route: 'reports-v4/create/adgroupreport',
      searchAlias: _TL_('Ad group report'),
      scope,
      available,
      apiKey: 'AdGroupPerformanceReport',
      description: _TL_('To more broadly compare delivery performance statistics by ad group, campaign, or account attributes rather than at the keyword level.'),
    }, {
      id: 'reports-v4-adreport',
      name: _TL_('Ad'),
      title: _TL_('See which ads are leading to clicks and conversions.'),
      fixedRoute: 'reports-v4/create/adreport',
      route: 'reports-v4/create/adreport',
      searchAlias: _TL_('Ad report'),
      scope,
      available,
      apiKey: 'AdPerformanceReport',
      description: _TL_('To help you determine which ads lead to clicks and conversions, and which are not performing. Having underperforming ads in your account can pull down the quality of your campaigns.'),
    }, {
      id: 'reports-v4-keywordreport',
      name: _TL_('Keyword'),
      title: _TL_('See which keywords are leading to clicks.'),
      fixedRoute: 'reports-v4/create/keywordreport',
      route: 'reports-v4/create/keywordreport',
      searchAlias: _TL_('Keyword report'),
      scope,
      available,
      apiKey: 'KeywordPerformanceReport',
      description: _TL_('To find out which keywords are triggering your ads and getting clicks. You can also identify keywords that aren\'t performing well to determine if you want to delete them.'),
    }, {
      id: 'reports-v4-negativekeywordconflictreport',
      name: _TL_('Negative keyword conflicts'),
      title: _TL_('See a set of keyword and negative keyword pairs that are blocking your bids from participating in the auction.'),
      fixedRoute: 'reports-v4/create/negativekeywordconflictreport',
      route: 'reports-v4/create/negativekeywordconflictreport',
      searchAlias: _TL_('Negative keyword conflicts report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'NegativeKeywordConflictReport',
      description: _TL_('This report tells you which keywords and negative keywords are in conflict, and whether the conflict is at the campaign or ad group level. Use this list to figure out which negative keywords to delete.'),
    }, {
      id: 'reports-v4-searchtermreport',
      name: _TL_('Search term'),
      // Duplicate
      title: _TL_('See which search terms are causing your ads to display.'),
      fixedRoute: 'reports-v4/create/searchtermreport',
      route: 'reports-v4/create/searchtermreport',
      searchAlias: _TL_('Search term report'),
      scope,
      available,
      apiKey: 'SearchQueryPerformanceReport',
      description: _TL_('To see what your audience is searching for when your ads are shown. You can use this information to make informed additions, removals, or edits to both your keyword and negative keyword lists.'),
    }, {
      id: 'reports-v4-shareofvoicereport',
      name: _TL_('Share of voice'),
      title: _TL_('See estimated impressions you might be losing.'),
      fixedRoute: 'reports-v4/create/shareofvoicereport',
      route: 'reports-v4/create/shareofvoicereport',
      searchAlias: _TL_('Share of voice report'),
      scope,
      available,
      apiKey: 'ShareOfVoiceReport',
      description: _TL_('To view the percentage of impression share (%)  you have in the marketplace, find out if you are missing opportunities based on your current campaign settings, and make changes to improve impression share. '),
    }, {
      id: 'reports-v4-desturlreport',
      name: _TL_('Destination URL'),
      title: _TL_('See how your landing pages are performing.'),
      fixedRoute: 'reports-v4/create/desturlreport',
      route: 'reports-v4/create/desturlreport',
      searchAlias: _TL_('Destination URL report'),
      scope,
      available,
      apiKey: 'DestinationURLPerformanceReport',
      description: _TL_('To identify landing pages that met audience expectations, resulting in high click or conversion ratios.'),
    }, {
      id: 'reports-v4-weburlreport',
      name: _TL_('Website URL (publisher)'),
      // Duplicate
      title: _TL_('See performance data for both search and content websites where your ads appear.'),
      fixedRoute: 'reports-v4/create/weburlreport',
      route: 'reports-v4/create/weburlreport',
      searchAlias: _TL_('Website URL (publisher) report'),
      scope,
      available,
      apiKey: 'PublisherUsagePerformanceReport',
      description: _TL_('To see if any website URLs aren\'t performing well enough for your campaign or ad group target settings. For example, if ad impressions at those URLs yield a low click-through-rate, then you might decide to exclude those websites from your campaign.'),
    }, {
      id: 'reports-v4-addynamictextreport',
      name: _TL_('Ad dynamic text'),
      title: _TL_('See which ad text strings are doing well.'),
      fixedRoute: 'reports-v4/create/addynamictextreport',
      route: 'reports-v4/create/addynamictextreport',
      searchAlias: _TL_('Ad dynamic text report'),
      scope,
      available,
      apiKey: 'AdDynamicTextPerformanceReport',
      description: _TL_('To identify which dynamic text strings are performing well and which strings you should consider changing.'),
    }, {
      id: 'reports-v4-audiencesreport',
      name: _TL_('Audiences'),
      title: _TL_('See how the ad groups that are associated with remarketing lists are performing.'),
      fixedRoute: 'reports-v4/create/audiencesreport',
      route: 'reports-v4/create/audiencesreport',
      searchAlias: _TL_('Audiences report'),
      scope,
      available,
      apiKey: 'AudiencePerformanceReport',
      description: _TL_('To evaluate performance of remarketing campaigns.'),
    }, {
      id: 'reports-v4-goalsreport',
      name: _TL_('Goals'),
      title: _TL_('See data on how people behave on your website.'),
      fixedRoute: 'reports-v4/create/goalsreport',
      route: 'reports-v4/create/goalsreport',
      searchAlias: _TL_('Goals report'),
      scope,
      available,
      apiKey: 'GoalsAndFunnelsReport',
      description: _TL_('To understand which campaigns and keywords are leading users to complete a specific type of conversion action. Note: these reports only show data for text ads and do not include shopping, dynamic search ads and audience campaigns conversion data.'),
    }, {
      id: 'reports-v4-conversionsreport',
      name: _TL_('Conversions'),
      title: _TL_('See conversion data for your campaigns during a specific time period.'),
      fixedRoute: 'reports-v4/create/conversionsreport',
      route: 'reports-v4/create/conversionsreport',
      searchAlias: _TL_('Conversions report'),
      scope,
      available,
      apiKey: 'ConversionPerformanceReport',
      description: _TL_('To understand which campaigns and keywords are leading customers to complete conversion actions. Note: these reports only show data for text ads and do not include shopping, dynamic search ads and audience campaigns conversion data.'),
    }, {
      id: 'reports-v4-feeditemreport',
      name: _TL_('Feed item'),
      title: _TL_('See performance on feed item level.'),
      fixedRoute: 'reports-v4/create/feeditemreport',
      route: 'reports-v4/create/feeditemreport',
      searchAlias: _TL_('Feed item report'),
      scope,
      available,
      apiKey: 'FeedItemPerformanceReport',
      description: _TL_('To view detailed performance statistics for each individual item from the Vertical ads inventory including the deleted and inactive Dynamic Data Feed items.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        permissions.dynamic.ReportingUpliftFeedItemReport,
    }, {
      id: 'reports-v4-conversionmodelcomparereport',
      name: _TL_('Conversion model compare'),
      title: _TL_('See influence of conversion attribution models.'),
      fixedRoute: 'reports-v4/create/conversionmodelcomparereport',
      route: 'reports-v4/create/conversionmodelcomparereport',
      searchAlias: _TL_('Conversion model compare report'),
      scope,
      available,
      apiKey: 'ConversionModelCompareReport',
      description: _TL_('To discover how different conversion attribution models impact the valuation of your campaigns.'),
    }, {
      id: 'reports-v4-msxaccountperformancereport',
      name: _TL_('MSX account performance'),
      title: _TL_('Access and review daily revenue trends.'),
      fixedRoute: 'reports-v4/create/msxaccountperformancereport',
      route: 'reports-v4/create/msxaccountperformancereport',
      searchAlias: _TL_('MSX account performance report'),
      scope,
      available,
      apiKey: 'MSXAccountPerformanceReport',
      description: _TL_('To access and review daily revenue trends.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        permissions.IsMSXAccountPerformanceReportEnabled,
    }, {
      id: 'reports-v4-SearchInsightPerformanceReport',
      name: _TL_('Search terms insights'),
      // Duplicate
      title: _TL_('See which search insight are causing your ads to display.'),
      fixedRoute: 'reports-v4/create/SearchInsightPerformanceReport',
      route: 'reports-v4/create/SearchInsightPerformanceReport',
      searchAlias: _TL_('Search terms insights report'),
      scope: accountLevelScopes,
      available,
      apiKey: 'SearchInsightPerformanceReport',
      description: _TL_('To gain valuable insights into how your customers search for your business. These insights analyze the specific search terms that triggered your ads during a selected time frame. The terms are then grouped into relevant search categories, providing key performance metrics for each category. Only Performance Max campaigns are supported at this time.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        permissions.dynamic.SearchInsightPerformanceReport,
    }, {
      id: 'reports-v4-CombinationPerformanceReport',
      name: _TL_('Combination performance'),
      title: _TL_('Identify the most frequently occurring asset combinations and provide a view of your combination performance.'),
      fixedRoute: 'reports-v4/create/CombinationPerformanceReport',
      route: 'reports-v4/create/CombinationPerformanceReport',
      searchAlias: _TL_('Combination performance report'),
      scope,
      available,
      apiKey: 'CombinationPerformanceReport',
      description: _TL_('To identify the most frequently occurring asset combinations and provide a view of your combination performance.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        permissions.dynamic.CombinationReport,
    },
  ],
};
