export const ABLVerificationStatus = Object.freeze({
  Failed: 1,
  OverrideFailed: 2,
  ActionRequired: 3,
  NotVerified: 4,
  InPending: 5,
  Verified: 6,
  OverrideVerified: 7,
  VerifiedLimited: 9,
  Failed_CN: 61,
  ActionRequired_CN: 62,
  NotVerified_CN: 63,
  InPending_CN: 64,
  Verified_CN: 65,
  LicenseExpired_CN: 66,
});

export const CNCompositeVerificationStatus = Object.freeze({
  Failed: 0,
  Verified: 1,
});

export const ABLVerificationFailedStatusList = [ABLVerificationStatus.Failed, ABLVerificationStatus.OverrideFailed];

export const ABLVerificationVerifiedStatusList = [ABLVerificationStatus.Verified, ABLVerificationStatus.OverrideVerified, ABLVerificationStatus.VerifiedLimited];

export const ABLVerificationStatusTextMapping = {
  [ABLVerificationStatus.Failed]: _TL_('Failed'),
  [ABLVerificationStatus.OverrideFailed]: _TL_('Failed'),
  [ABLVerificationStatus.ActionRequired]: _TL_('Action required'),
  [ABLVerificationStatus.NotVerified]: _TL_('Not verified'),
  [ABLVerificationStatus.InPending]: _TL_('Pending'),
  [ABLVerificationStatus.Verified]: _TL_('Verified'),
  [ABLVerificationStatus.OverrideVerified]: _TL_('Verified'),
  [ABLVerificationStatus.VerifiedLimited]: _TL_('Verified limited'),
  [ABLVerificationStatus.Failed_CN]: _TL_('Failed'),
  [ABLVerificationStatus.ActionRequired_CN]: _TL_('Extension needed'),
  [ABLVerificationStatus.NotVerified_CN]: _TL_('Not verified'),
  [ABLVerificationStatus.InPending_CN]: _TL_('Pending'),
  [ABLVerificationStatus.Verified_CN]: _TL_('Verified'),
  [ABLVerificationStatus.LicenseExpired_CN]: _TL_('Expired'),
};

export const CNCompositeVerificationStatusTextMapping = {
  [CNCompositeVerificationStatus.Failed]: _TL_('Failed'),
  [CNCompositeVerificationStatus.Verified]: _TL_('Verified'),
};

export const AccountVerificationStatus = Object.freeze({
  Failed: 1,
  OverrideFailed: 2,
  ActionRequired: 3,
  NeedVerified: 4,
  InPending: 5,
  Verified: 6,
  OverrideVerified: 7,
  NoNeedVerified: 8,
  VerifiedLimited: 9,
});

export const ChinaAccountVerificationStatus = Object.freeze({
  Failed: 61,
  ActionRequired: 62,
  NeedVerified: 63,
  InPending: 64,
  Verified: 65,
  LicenseExpired: 66,
});

export const ChinaAIVStatusToGlobalAIVStatusMapping = {
  [ChinaAccountVerificationStatus.Failed]: AccountVerificationStatus.Failed,
  [ChinaAccountVerificationStatus.ActionRequired]: AccountVerificationStatus.ActionRequired,
  [ChinaAccountVerificationStatus.NeedVerified]: AccountVerificationStatus.NeedVerified,
  [ChinaAccountVerificationStatus.InPending]: AccountVerificationStatus.InPending,
  [ChinaAccountVerificationStatus.Verified]: AccountVerificationStatus.Verified,
  [ChinaAccountVerificationStatus.LicenseExpired]: AccountVerificationStatus.Failed,
};

export const AccountVerificationFailedStatusList = [AccountVerificationStatus.Failed, AccountVerificationStatus.OverrideFailed];

export const AccountVerificationVerifiedStatusList = [AccountVerificationStatus.Verified, AccountVerificationStatus.OverrideVerified, AccountVerificationStatus.VerifiedLimited];

export const AccountToAblVerificationStatusRelation = {
  [AccountVerificationStatus.Failed]: ABLVerificationFailedStatusList,
  [AccountVerificationStatus.OverrideFailed]: ABLVerificationFailedStatusList,
  [AccountVerificationStatus.ActionRequired]: [ABLVerificationStatus.ActionRequired],
  [AccountVerificationStatus.NeedVerified]: [ABLVerificationStatus.NotVerified],
  [AccountVerificationStatus.InPending]: [ABLVerificationStatus.InPending],
  [AccountVerificationStatus.Verified]: ABLVerificationVerifiedStatusList,
  [AccountVerificationStatus.OverrideVerified]: ABLVerificationVerifiedStatusList,
  [AccountVerificationStatus.NoNeedVerified]: null, // This value indicates that any ABLVerificationStatus is OK. Choosing nul arbitrary, can be changed later per need.
  [AccountVerificationStatus.VerifiedLimited]: ABLVerificationVerifiedStatusList,
};

export const AIVStatusLabelPropertyMapping = {
  [AccountVerificationStatus.Failed]: {
    glyph: 'ba-Error',
    iconStyle: 'error',
    textStyle: 'error',
    labelKey: _TL_('Failed'),
    labelKeyInternal: _TL_('Failed'),
  },
  [AccountVerificationStatus.OverrideFailed]: {
    glyph: 'ba-Error',
    iconStyle: 'error',
    textStyle: 'error',
    labelKey: _TL_('Failed'),
    labelKeyInternal: _TL_('Appealed Failed'),
  },
  [AccountVerificationStatus.ActionRequired]: {
    glyph: 'ba-Warning',
    iconStyle: 'error',
    textStyle: 'error',
    labelKey: _TL_('Action required'),
    labelKeyInternal: _TL_('Action required'),
  },
  [AccountVerificationStatus.NeedVerified]: {
    glyph: 'ba-Warning',
    iconStyle: 'warning',
    textStyle: 'warning',
    labelKey: _TL_('Not verified'),
    labelKeyInternal: _TL_('Not verified'),
  },
  [AccountVerificationStatus.InPending]: {
    glyph: 'ba-HourGlass',
    iconStyle: 'default',
    textStyle: 'default',
    labelKey: _TL_('Pending'),
    labelKeyInternal: _TL_('Pending'),
  },
  [AccountVerificationStatus.Verified]: {
    glyph: 'ba-VerifiedBrandSolid',
    iconStyle: 'primary',
    labelKey: _TL_('Verified advertiser'),
    labelKeyInternal: _TL_('Verified advertiser'),
  },
  [AccountVerificationStatus.OverrideVerified]: {
    glyph: 'ba-VerifiedBrandSolid',
    iconStyle: 'primary',
    labelKey: _TL_('Verified advertiser'),
    labelKeyInternal: _TL_('Appealed verified advertiser'),
  },
  [AccountVerificationStatus.NoNeedVerified]: {
    glyph: 'ba-Info',
    iconStyle: 'default',
    textStyle: 'default',
    labelKey: _TL_('Verification not needed'),
    labelKeyInternal: _TL_('Verification not needed'),
  },
  [AccountVerificationStatus.VerifiedLimited]: {
    glyph: 'ba-VerifiedBrandSolid',
    iconStyle: 'primary',
    labelKey: _TL_('Verified limited'),
    labelKeyInternal: _TL_('Verified limited'),
  },
  // Note: China AIV status label mapping, the information will not display in internal tool so no labelKeyInternal added.
  [ChinaAccountVerificationStatus.Failed]: {
    glyph: 'ba-Error',
    iconStyle: 'error',
    textStyle: 'error',
    labelKey: _TL_('Failed'),
  },
  [ChinaAccountVerificationStatus.ActionRequired]: {
    glyph: 'ba-Warning',
    iconStyle: 'warning',
    textStyle: 'error',
    labelKey: _TL_('Extension needed'),
  },
  [ChinaAccountVerificationStatus.NeedVerified]: {
    glyph: 'ba-Warning',
    iconStyle: 'warning',
    textStyle: 'warning',
    labelKey: _TL_('Not verified'),
  },
  [ChinaAccountVerificationStatus.InPending]: {
    glyph: 'ba-HourGlass',
    iconStyle: 'default',
    textStyle: 'default',
    labelKey: _TL_('Pending'),
  },
  [ChinaAccountVerificationStatus.Verified]: {
    glyph: 'ba-VerifiedBrandSolid',
    iconStyle: 'primary',
    labelKey: _TL_('Verified advertiser'),
  },
  [ChinaAccountVerificationStatus.LicenseExpired]: {
    glyph: 'ba-Blocked2',
    iconStyle: 'error',
    labelKey: _TL_('Verification expired'),
  },
};

export const VerificationType = {
  Individual: '1',
  Business: '2',
};

export const DocumentVerifiedStatus =
{
  NotVerified: 1,
  Verified: 2,
  Failed: 3,
};

export const UnableTriggerAivReason =
{
  InternalUser: 1,
  NoRemainingRetryCount: 2,
  UserRoleMismatch: 3,
  NotAccountOwner: 4,
  InitializeInProgress: 5,
  OverrideFailed: 6,
};


export const OrderedUnableTriggerAivReasons =
  [
    UnableTriggerAivReason.InternalUser,
    UnableTriggerAivReason.NotAccountOwner,
    UnableTriggerAivReason.UserRoleMismatch,
    UnableTriggerAivReason.OverrideFailed,
    UnableTriggerAivReason.NoRemainingRetryCount,
    UnableTriggerAivReason.InitializeInProgress,
  ];

export const AivFailureReasons = {
  Onfido_Rejected_ImageIntegrity_ImageQuality: 101,
  Onfido_Rejected_ImageIntegrity_SupportedDocument: 102,
  Onfido_Rejected_ImageIntegrity_ColourPicture: 103,
  Onfido_Caution_DataConsistency_MultipleDataSourcesPresent: 104,
  Onfido_Caution_VisualAuthenticity_OriginalDocumentPresent: 105,
  Onfido_Caution_FaceComparison_FaceMatch: 106,
  Onfido_Caution_DataValidation_DocumentExpiration: 107,
  Onfido_Caution_DataComparison_FirstName: 108,
  Onfido_Caution_DataComparison_LastName: 109,
  Onfido_Caution_ImageIntegrity_ConclusiveDocumentQuality: 110,
  Onfido_Caution_ImageIntegrity_ColorPicture: 111,
  Onfido_Caution_ImageIntegrity_PictureUnavailableInDocument: 112,
  OneVet_Email_Verification_Failure: 201,
  OneVet_Business_Verification_Failure: 202,
  OneVet_Domain_Verification_Failure: 203,

  // China Aiv Reject Reason, from 1000000 -> 1000032
  SUBJECT_QUALIFICATION_THE_QUALIFICATION_PICTURE_IS_NOT_CLEAR: 1000000,
  SUBJECT_QUALIFICATION_QUALIFICATION_EXPIRED: 1000001,
  SUBJECT_QUALIFICATION_DISCREPANCY_IN_BUSINESS_SCOPE: 1000002,
  SUBJECT_QUALIFICATION_THE_ACCOUNT_OPENING_ENTITY_IS_OPERATING_ABNORMALLY: 1000003,
  SUBJECT_QUALIFICATION_THE_UNIFIED_SOCIAL_CREDIT_CODE_WAS_FILLED_IN_INCORRECTLY: 1000004,
  SUBJECT_QUALIFICATION_FALSE_QUALIFICATION: 1000005,
  SUBJECT_QUALIFICATION_QUALIFICATION_PICTURE_UPLOAD_ERROR: 1000006,
  SUBJECT_QUALIFICATION_THE_QUALIFICATION_FILE_HAS_A_RESTRICTED_WATERMARK: 1000007,
  SUBJECT_QUALIFICATION_THE_COMPANY_NAME_IS_INCORRECT: 1000008,
  SUBJECT_QUALIFICATION_THE_QUALIFICATION_DOCUMENTS_ARE_INCOMPLETE: 1000009,
  SUBJECT_QUALIFICATION_PLEASE_CONTACT_YOUR_SALES_AGENT_FOR_ADVICE: 1000010,
  INDUSTRY_QUALIFICATION_THE_QUALIFICATION_PICTURE_IS_NOT_CLEAR: 1000011,
  INDUSTRY_QUALIFICATION_QUALIFICATION_EXPIRED: 1000012,
  INDUSTRY_QUALIFICATION_DISCREPANCY_IN_BUSINESS_SCOPE: 1000013,
  INDUSTRY_QUALIFICATION_THE_ACCOUNT_OPENING_ENTITY_IS_OPERATING_ABNORMALLY: 1000014,
  INDUSTRY_QUALIFICATION_THE_UNIFIED_SOCIAL_CREDIT_CODE_WAS_FILLED_IN_INCORRECTLY: 1000015,
  INDUSTRY_QUALIFICATION_FALSE_QUALIFICATION: 1000016,
  INDUSTRY_QUALIFICATION_QUALIFICATION_PICTURE_UPLOAD_ERROR: 1000017,
  INDUSTRY_QUALIFICATION_THE_QUALIFICATION_FILE_HAS_A_RESTRICTED_WATERMARK: 1000018,
  INDUSTRY_QUALIFICATION_THE_COMPANY_NAME_IS_INCORRECT: 1000019,
  INDUSTRY_QUALIFICATION_THE_QUALIFICATION_DOCUMENTS_ARE_INCOMPLETE: 1000020,
  INDUSTRY_QUALIFICATION_PLEASE_CONTACT_YOUR_SALES_AGENT_FOR_ADVICE: 1000021,
  OTHERS_THE_QUALIFICATION_PICTURE_IS_NOT_CLEAR: 1000022,
  OTHERS_QUALIFICATION_EXPIRED: 1000023,
  OTHERS_DISCREPANCY_IN_BUSINESS_SCOPE: 1000024,
  OTHERS_THE_ACCOUNT_OPENING_ENTITY_IS_OPERATING_ABNORMALLY: 1000025,
  OTHERS_THE_UNIFIED_SOCIAL_CREDIT_CODE_WAS_FILLED_IN_INCORRECTLY: 1000026,
  OTHERS_FALSE_QUALIFICATION: 1000027,
  OTHERS_QUALIFICATION_PICTURE_UPLOAD_ERROR: 1000028,
  OTHERS_THE_QUALIFICATION_FILE_HAS_A_RESTRICTED_WATERMARK: 1000029,
  OTHERS_THE_COMPANY_NAME_IS_INCORRECT: 1000030,
  OTHERS_THE_QUALIFICATION_DOCUMENTS_ARE_INCOMPLETE: 1000031,
  OTHERS_PLEASE_CONTACT_YOUR_SALES_AGENT_FOR_ADVICE: 1000032,
};

export const AivAblValidationRuleName = 'AccountIdentityVerification';

export const AivAppealStatus = Object.freeze({
  Allowed: 'Allowed',
  Submitted: 'Submitted',
  Failed: 'Failed',
  InValid: 'InValid',
});

export const ValidAivAppealStatusList = [AivAppealStatus.Allowed, AivAppealStatus.Submitted, AivAppealStatus.Failed];
