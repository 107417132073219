import { available, scope } from './common';

export const productAds = {
  id: 'reports-v4-category-productads',
  name: _TL_('Product ads'),
  title: _TL_('Product ad reports give you a closer look at how your Bing Shopping campaigns are performing based on product partition and product offer data.'),
  fixedRoute: 'reports-v4/category/productads',
  route: 'reports-v4/category/productads',
  scope,
  available,
  children: [
    {
      id: 'reports-v4-productpartitionreport',
      name: _TL_('Product partition'),
      title: _TL_('See the performance of your Product Groups in Shopping campaigns '),
      fixedRoute: 'reports-v4/create/productpartitionreport',
      route: 'reports-v4/create/productpartitionreport',
      searchAlias: _TL_('Product partition report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'ProductPartitionPerformanceReport',
      description: _TL_('To see the performance data for the product groups in your Shopping campaigns and to optimize your campaigns accordingly .'),
    }, {
      id: 'reports-v4-productpartitionunitreport',
      name: _TL_('Product partition unit'),
      title: _TL_('See product partition unit data of your Product Groups in Shopping campaigns'),
      fixedRoute: 'reports-v4/create/productpartitionunitreport',
      route: 'reports-v4/create/productpartitionunitreport',
      searchAlias: _TL_('Product partition unit report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'ProductPartitionUnitPerformanceReport',
      description: _TL_('To see product partition unit data of your Product Groups in Shopping campaigns.'),
    }, {
      id: 'reports-v4-productdimensionreport',
      name: _TL_('Product dimension'),
      title: _TL_('See the performance of your Products in Shopping campaigns '),
      fixedRoute: 'reports-v4/create/productdimensionreport',
      route: 'reports-v4/create/productdimensionreport',
      searchAlias: _TL_('Product dimension report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'ProductDimensionPerformanceReport',
      description: _TL_('To figure out which of your products are triggering ads and getting most clicks and optimize the ones not performing so well. '),
    }, {
      id: 'reports-v4-productsearchtermreport',
      name: _TL_('Product search term'),
      title: _TL_('See which search terms are causing your ads to display.'),
      fixedRoute: 'reports-v4/create/productsearchtermreport',
      route: 'reports-v4/create/productsearchtermreport',
      searchAlias: _TL_('Product search term report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'ProductSearchQueryPerformanceReport',
      description: _TL_('To see what your audience is searching for when your Product ads are shown.'),
    }, {
      id: 'reports-v4-productmatchcountreport',
      name: _TL_('Product match count'),
      title: _TL_('See how products are distributed acrocss given product tree.'),
      fixedRoute: 'reports-v4/create/productmatchcountreport',
      route: 'reports-v4/create/productmatchcountreport',
      searchAlias: _TL_('Product match count report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'ProductMatchCountReport',
      description: _TL_('To see if you are covering and bidding across your Microsoft Shopping campaigns inventory. Note that this only provides the matched data for your current product group within active campaigns, and you cannot obtain historical views.'),
    }, {
      id: 'reports-v4-productnegativekeywordreport',
      name: _TL_('Product negative keyword conflicts'),
      title: _TL_('See a set of negative keyword pairs that are blocking your products from participating in the auction.'),
      fixedRoute: 'reports-v4/create/productnegativekeywordreport',
      route: 'reports-v4/create/productnegativekeywordreport',
      searchAlias: _TL_('Product negative keyword conflicts report'),
      scope,
      available,
      apiKey: 'ProductNegativeKeywordConflictReport',
      description: _TL_('This report tells you which products and negative keywords are in conflict, and whether the conflict is at the campaign or ad group level. Use this list to figure out which negative keywords to delete.'),
    },
  ],
};
