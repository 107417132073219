export const FCAVerificationType = {
  FS: 1,
  Gov: 2,
  NonFS: 3,
};

export const FCAAccountStatus = {
  Pending: 1,
  Verified: 2,
  VerifiedLimited: 3,
  Failed: 4,
  NotStarted: 5,
  NoNeed: 6,
};

export const FCAAccountDomainStatus = {
  Create: 1,
  NQPPending: 2,
  Verified: 3,
  Failed: 4,
  Deleted: 5,
};

export const FCAVerificationTypeString = {
  [FCAVerificationType.FS]: _TL_('UK FCA authorized financial services advertiser'),
  [FCAVerificationType.Gov]: _TL_('Government entity'),
  [FCAVerificationType.NonFS]: _TL_('Non-financial services advertiser'),
};

export const FCAVerificationAccountStatusString = {
  [FCAAccountStatus.Pending]: _TL_('Pending'),
  [FCAAccountStatus.Verified]: _TL_('Verified'),
  [FCAAccountStatus.VerifiedLimited]: _TL_('Partially verified'),
  [FCAAccountStatus.Failed]: _TL_('Failed'),
  [FCAAccountStatus.NotStarted]: _TL_('Not started'),
  [FCAAccountStatus.NoNeed]: _TL_('Not in pilot'),
};

export const CanTriggerFCAScenario = {
  Create: 'CanTriggerFCACreate',
  Update: 'CanTriggerFCAUpdate',
};

export const UnableTriggerFCAReason = {
  UserRoleMismatch: 1,
  NotAccountOwner: 2,
  FCAStatusMismatch: 3,
  Unauthorized: 4,
  NotInPilot: 5,
};
