import { available, scope } from './common';

export const appAds = {
  id: 'reports-v4-category-app',
  name: _TL_('App ads'),
  title: _TL_('App ads'),
  scope,
  available,
  checkPermission: /* istanbul ignore next */ permissions =>
    permissions.dynamic.AppAdsCampaignPhaseOne,
  children: [
    {
      id: 'reports-v4-appsperformancereport',
      name: _TL_('App performance'),
      title: _TL_('See the performance of your app campaigns.'),
      fixedRoute: 'reports-v4/create/appperformancereport',
      route: 'reports-v4/create/appperformancereport',
      searchAlias: _TL_('App performance report'),
      scope,
      available,
      apiKey: 'AppsPerformanceReport',
      description: _TL_('To see the performance of your app campaigns.'),
    },
  ],
};
