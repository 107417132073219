import { adgroupCreate } from './adgroup-create';
import { campaignCreate } from './campaign-create';
import { associationCreate } from './association-create';
import { audienceCreate, audienceEdit } from './audience-create';
import { accountCreate } from './account-create';
import { accountLinking } from './account-linking';
import { accountsTransfer } from './accounts-transfer';
import { accountsUnlinking } from './accounts-unlinking';
import { pendingTransferRequest } from './pending-transfer-request';
import { performanceTargetsCreate } from './performance-targets-create';
import { performanceTargetsOverview } from './performance-targets-overview';
import { buildCustomReportsPage, editCustomReportPage } from './report-create';
import { sharedBudgetsEditPage } from './shared-budget-edit';
import { portfolioBidStrategyDetails } from './portfolio-bid-strategy-details';
import { conversionGoalsCreate, conversionGoalsAutoCreate, conversionGoalsEdit } from './conversion-goals-create';
import { conversionValueRulesCreate, conversionValueRulesEdit } from './conversion-value-rules-create';
import { uetTagsCreate, uetTagsEdit } from './uet-tags-create';
import { adCustomizerFeedsItems } from './ad-customizer-feeds-items';
import { dynamicDataFeedsItems } from './dynamic-data-feeds-items';
import { pageFeedsItems } from './page-feeds-items';
import { ruleDetail } from './rule-detail';
import { bulkEditDetail } from './bulk-edit-detail';
import { scriptsDetail } from './scripts';
import { negativeKeywordsListsDetail } from './negative-keywords-lists-details';
import { merchanteCenterStoreCreate } from './merchant-center-store-create';
import { recommendationHistory } from './recommendation-history';
import { bulkUploadsBuildSpreadsheet } from './bulk-uploads-build-spreadsheet';
import { optOutForMinors } from './opt-out-minors';
import { recommendationAutoApplySettings } from './recommendation-auto-apply-settings';
import { recommendationAutoApplyBulkUpload } from './recommendation-auto-apply-bulk-upload';
import { importCredentialIdPages } from './import-credential-id';
import { queryInsights } from './query-insights';
import { pmaxDetails, assetGroupCreate, assetGroupEdit } from './pmax-details';
import { appAdsAssetGroups, appAdsAssetGroupsCreation, appAdsAssetGroupsEdit } from './app-ads-details';
import { budgetCenterCampaignBudgetTool } from './budget-center-campaign-budget-tool';
import { recommendationDemo } from './recommendation-demo';
import { copilotReport } from './copilot-report';
import { adEditor } from './ad-editor';
import { unifiedAppAdsAssetGroups, unifiedAppAdsAssetGroupsCreation, unifiedAppAdsAssetGroupsEdit } from './unified-app-ads-details';

export const standalonePages = [
  campaignCreate,
  adEditor,
  adgroupCreate,
  associationCreate,
  audienceCreate,
  performanceTargetsCreate,
  performanceTargetsOverview,
  sharedBudgetsEditPage,
  portfolioBidStrategyDetails,
  audienceEdit,
  accountCreate,
  accountLinking,
  accountsUnlinking,
  accountsTransfer,
  appAdsAssetGroups,
  unifiedAppAdsAssetGroups,
  unifiedAppAdsAssetGroupsCreation,
  unifiedAppAdsAssetGroupsEdit,
  appAdsAssetGroupsCreation,
  appAdsAssetGroupsEdit,
  pendingTransferRequest,
  conversionGoalsCreate,
  conversionGoalsAutoCreate,
  conversionGoalsEdit,
  uetTagsCreate,
  uetTagsEdit,
  conversionValueRulesCreate,
  conversionValueRulesEdit,
  adCustomizerFeedsItems,
  pageFeedsItems,
  dynamicDataFeedsItems,
  ruleDetail,
  bulkEditDetail,
  scriptsDetail,
  negativeKeywordsListsDetail,
  merchanteCenterStoreCreate,
  recommendationHistory,
  bulkUploadsBuildSpreadsheet,
  optOutForMinors,
  recommendationAutoApplySettings,
  recommendationAutoApplyBulkUpload,
  importCredentialIdPages,
  queryInsights,
  pmaxDetails,
  assetGroupCreate,
  assetGroupEdit,
  budgetCenterCampaignBudgetTool,
  recommendationDemo,
  copilotReport,
  buildCustomReportsPage,
  editCustomReportPage,
];
