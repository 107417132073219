export const uploadedReconReportsPage = {
  id: 'property-center-uploadedreconreports',
  name: _TL_('Uploaded Reconciliation Reports'),
  route: 'propertycenter/uploadedreconreports',
  checkPermission: (permissions) => {
    const {
      ReconciliationReport, PayPerStay, HotelCenterRemoval,
    } = permissions.dynamic;
    return ReconciliationReport && PayPerStay && HotelCenterRemoval;
  },
};

export const processedReconReportsPage = {
  id: 'property-center-processedreconreports',
  name: _TL_('Processed Reconciliation Reports'),
  route: 'propertycenter/processedreconreports',
  checkPermission: (permissions) => {
    const {
      ReconciliationReport, PayPerStay, HotelCenterRemoval,
    } = permissions.dynamic;
    return ReconciliationReport && PayPerStay && HotelCenterRemoval;
  },
};

const reconcilliationReportPages = [uploadedReconReportsPage, processedReconReportsPage];

export const reconReportPage = {
  id: 'property-center-reconciliationreport',
  name: _TL_('Reconciliation Report'),
  route: 'propertycenter/reconciliationreport',
  checkPermission: (permissions) => {
    const {
      ReconciliationReport, PayPerStay, HotelCenterRemoval,
    } = permissions.dynamic;
    return ReconciliationReport && PayPerStay && HotelCenterRemoval;
  },
  children: reconcilliationReportPages,
};

