import {
  defaultReportsPage,
  myReportsPage,
  editReportPage,
} from '../configs/reports-v4';
import {
  myCustomReportsPage,
  reportTemplatePage,
} from '../configs/custom-reports';
import { allScopes, alwaysDisplayed, alwaysDisplayedItem, buildTree } from '../configs/shared';


const reports = {
  id: 'reports-v4',
  name: _TL_('Reporting'),
  description: _TL_('View performance data with instant reports or by creating custom reports.'),
  route: 'reports-v4',
  fixedRoute: 'reports-v4',
  scope: allScopes,
  campaignTypes: alwaysDisplayed,
  checkPermission: permissions => !permissions.dynamic.CustomReports,
  children: [
    defaultReportsPage,
    myReportsPage,
    {
      ...editReportPage,
      searchable: false,
    },
  ],
};

const customReports = {
  id: 'reports-v4',
  name: _TL_('Reporting'),
  description: _TL_('View performance data with instant reports or by creating custom reports.'),
  route: 'customreports',
  fixedRoute: 'customreports',
  scope: allScopes,
  campaignTypes: alwaysDisplayed,
  checkPermission: permissions => permissions.dynamic.CustomReports,
  children: [
    myCustomReportsPage,
    reportTemplatePage,
  ],
};

export const tree = buildTree([reports], alwaysDisplayedItem, true, undefined, true)[0];
export const customReportTree = buildTree([customReports], alwaysDisplayedItem, true, undefined, true)[0];


export { tree as reports };
export { customReportTree as customReports };
