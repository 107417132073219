import { offlineConversions, uetTags, conversionGoals, customerAcquisition } from '../configs/tools/conversion-tracking';
import { alwaysDisplayedItem, buildTree } from '../configs/shared';
import { modeledConversions } from '../configs/tools/conversion-tracking/modeled-conversions';

const conversions = {
  id: 'conversions',
  name: _TL_('Conversions'),
  description: _TL_('Get behavioral insights for conversion tracking.'),
  children: [
    {
      ...uetTags,
      children: [{
        id: 'uet-tags-pages',
        children: uetTags.children,
      }],
    },
    conversionGoals,
    {
      ...offlineConversions,
      children: [{
        id: 'offline-conversions-pages',
        children: offlineConversions.children,
      }],
    },
    {
      ...modeledConversions,
      children: [{
        id: 'modeled-conversions-pages',
        children: modeledConversions.children,
      }],
    },
    customerAcquisition,
  ],
};

const tree = buildTree([conversions], alwaysDisplayedItem, true, undefined, true)[0];

export { tree as conversions };
