import { available, scope } from './common';

export const advancedInsights = {
  id: 'customreports-category-advancedinsights',
  name: _TL_('Advanced insights'),
  title: _TL_('Find advanced insights into how your advertisers are performing'),
  scope,
  available,
  checkPermission: /* istanbul ignore next */ permissions =>
    !!permissions.IsInternalUser,
  children: [
    {
      id: 'customreports-sixweekaccountactivityreport',
      name: _TL_('Account - 6 weeks'),
      title: _TL_('See how individual accounts are performing with excel charts and graphs'),
      fixedRoute: 'customreports/create/sixweekaccountactivityreport',
      route: 'customreports/create/sixweekaccountactivityreport',
      searchAlias: _TL_('Account - 6 weeks report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'AccountPerformanceReport-SixWeeks',
      description: _TL_('See how individual accounts are performing with excel charts and graphs'),
      checkPermission: /* istanbul ignore next */ permissions =>
        !!permissions.IsInternalUser,
    }, {
      id: 'customreports-twomonthaccountactivityreport',
      name: _TL_('Account - 2 months (MoM)'),
      title: _TL_('See how individual accounts are performing with excel charts and graphs'),
      fixedRoute: 'customreports/create/twomonthaccountactivityreport',
      route: 'customreports/create/twomonthaccountactivityreport',
      searchAlias: _TL_('Account - 2 months (MoM) report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'AccountPerformanceReport-TwoMonths',
      description: _TL_('See how individual accounts are performing with excel charts and graphs'),
      checkPermission: /* istanbul ignore next */ permissions =>
        !!permissions.IsInternalUser,
    }, {
      id: 'customreports-twoyearaccountactivityreport',
      name: _TL_('Account - 2 years (YoY)'),
      title: _TL_('See how individual accounts are performing with excel charts and graphs'),
      fixedRoute: 'customreports/create/twoyearaccountactivityreport',
      route: 'customreports/create/twoyearaccountactivityreport',
      searchAlias: _TL_('Account - 2 years (YoY) report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'AccountPerformanceReport-TwoYears',
      description: _TL_('See how individual accounts are performing with excel charts and graphs'),
      checkPermission: /* istanbul ignore next */ permissions =>
        !!permissions.IsInternalUser,
    }, {
      id: 'customreports-advancedinsightsshareofvoicereport',
      name: _TL_('Account - Share of voice'),
      title: _TL_('See estimated impressions you might be losing.'),
      fixedRoute: 'customreports/create/advancedinsightsshareofvoicereport',
      route: 'customreports/create/advancedinsightsshareofvoicereport',
      searchAlias: _TL_('Account - Share of voice report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'AccountPerformanceReport-ShareOfVoice',
      description: _TL_('To view impression share (%) of successful bids for each keyword, and identify opportunities to increase impression share.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        !!permissions.IsInternalUser,
    },
    {
      id: 'customreports-searchverticalcategoryinsightsreport',
      name: _TL_('Category insights'),
      title: _TL_('To view the performance of industry categories on the Bing marketplace. This includes search and syndicated traffic.'),
      fixedRoute: 'customreports/create/searchverticalcategoryinsightsreport',
      route: 'customreports/create/searchverticalcategoryinsightsreport',
      searchAlias: _TL_('Search vertical category insights report'),
      scope,
      available,
      apiKey: 'SearchVerticalCategoryInsightsReport',
      description: _TL_('To view the performance of industry categories on the Bing marketplace. This includes search and syndicated traffic.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        permissions.dynamic.DefaultCustomReport,
    },
    {
      id: 'customreports-searchverticalcategoryclicksharereport',
      name: _TL_('Category click coverage'),
      title: _TL_('To view the share of marketplace demand your account(s) have gained in a specific industry category.'),
      fixedRoute: 'customreports/create/searchverticalcategoryclicksharereport',
      route: 'customreports/create/searchverticalcategoryclicksharereport',
      searchAlias: _TL_('Category Click Coverage Report'),
      scope,
      available,
      apiKey: 'SearchVerticalCategoryClickShareReport',
      description: _TL_('To view the share of marketplace demand your account(s) have gained in a specific industry category.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        permissions.dynamic.DefaultCustomReport,
    },
  ],
};
