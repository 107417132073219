export const AccountLanguageI18nMap = {
  1: _TL_('(Afan) Oromo'),
  2: _TL_('Abkhazian'),
  3: _TL_('Afar'),
  4: _TL_('Afrikaans'),
  5: _TL_('Albanian'),
  6: _TL_('Amharic'),
  7: _TL_('Arabic'),
  8: _TL_('Armenian'),
  9: _TL_('Assamese'),
  10: _TL_('Aymara'),
  11: _TL_('Azerbaijani'),
  12: _TL_('Bashkir'),
  13: _TL_('Basque'),
  14: _TL_('Bangla'),
  15: _TL_('Bhutani'),
  16: _TL_('Bihari'),
  17: _TL_('Bislama'),
  18: _TL_('Breton'),
  19: _TL_('Bulgarian'),
  20: _TL_('Burmese'),
  21: _TL_('Belarusian'),
  22: _TL_('Cambodian'),
  23: _TL_('Catalan'),
  24: _TL_('Traditional Chinese'),
  25: _TL_('Corsican'),
  26: _TL_('Croatian'),
  27: _TL_('Czech'),
  28: _TL_('Danish'),
  29: _TL_('Dutch'),
  30: _TL_('English'),
  31: _TL_('Esperanto'),
  32: _TL_('Estonian'),
  33: _TL_('Faeroese'),
  34: _TL_('Fiji'),
  35: _TL_('Finnish'),
  36: _TL_('French'),
  37: _TL_('Frisian'),
  38: _TL_('Galician'),
  39: _TL_('Georgian'),
  40: _TL_('German'),
  41: _TL_('Greek'),
  42: _TL_('Greenlandic'),
  43: _TL_('Guarani'),
  44: _TL_('Gujarati'),
  45: _TL_('Hausa'),
  46: _TL_('Hebrew'),
  47: _TL_('Hindi'),
  48: _TL_('Hungarian'),
  49: _TL_('Icelandic'),
  50: _TL_('Indonesian'),
  51: _TL_('Interlingua'),
  52: _TL_('Interlingue'),
  53: _TL_('Inupiak'),
  54: _TL_('Irish'),
  55: _TL_('Italian'),
  56: _TL_('Japanese'),
  57: _TL_('Javanese'),
  58: _TL_('Kannada'),
  60: _TL_('Kazakh'),
  61: _TL_('Kinyarwanda'),
  62: _TL_('Kirghiz'),
  63: _TL_('Kirundi'),
  64: _TL_('Korean'),
  66: _TL_('Laothian'),
  67: _TL_('Latin'),
  68: _TL_('Latvian'),
  69: _TL_('Lingala'),
  70: _TL_('Lithuanian'),
  71: _TL_('Macedonian'),
  72: _TL_('Malagasy'),
  73: _TL_('Maylay'),
  74: _TL_('Maylayalam'),
  75: _TL_('Maltese'),
  76: _TL_('Maori'),
  77: _TL_('Marathi'),
  78: _TL_('Romanian'),
  79: _TL_('Mongolian'),
  80: _TL_('Nauru'),
  81: _TL_('Nepali'),
  82: _TL_('Norwegian'),
  83: _TL_('Occitan'),
  84: _TL_('Odia'),
  85: _TL_('Pashto'),
  86: _TL_('Persian'),
  87: _TL_('Polish'),
  88: _TL_('Portuguese'),
  89: _TL_('Punjabi'),
  90: _TL_('Quechua'),
  91: _TL_('Rhaeto-Romance'),
  92: _TL_('Romainian'),
  93: _TL_('Russian'),
  94: _TL_('Samoan'),
  95: _TL_('Sangro'),
  96: _TL_('Sanskrit'),
  97: _TL_('Scottish Gaelic'),
  98: _TL_('Serbian'),
  99: _TL_('Serbian'),
  100: _TL_('Sesotho'),
  101: _TL_('Setswana'),
  102: _TL_('Shona'),
  103: _TL_('Sindhi'),
  104: _TL_('Singhalese'),
  105: _TL_('Siswati'),
  106: _TL_('Slovak'),
  107: _TL_('Slovenian'),
  108: _TL_('Somali'),
  109: _TL_('Spanish'),
  110: _TL_('Spanish'),
  111: _TL_('Sundanese'),
  112: _TL_('Swedish'),
  113: _TL_('Tagalog'),
  114: _TL_('Tajik'),
  115: _TL_('Tamil'),
  116: _TL_('Tatar'),
  117: _TL_('Telegu'),
  118: _TL_('Thai'),
  119: _TL_('Tibetan'),
  120: _TL_('Tigrinya'),
  121: _TL_('Tonga (Nyasa)'),
  122: _TL_('Tsonga'),
  123: _TL_('Turkish'),
  124: _TL_('Turkmen'),
  125: _TL_('Twi'),
  126: _TL_('Ukrainian'),
  127: _TL_('Urdu'),
  128: _TL_('Uzbek'),
  129: _TL_('Vietnamese'),
  130: _TL_('Volapuk'),
  131: _TL_('Welsh'),
  132: _TL_('Wolof'),
  133: _TL_('Xhosa'),
  134: _TL_('Yiddish'),
  135: _TL_('Yoruba'),
  136: _TL_('Zulu'),
  137: _TL_('Simplified Chinese'),
};
