export const NotificationCategoryFieldNames = {
  NotificationCategorySettings: 'NotificationCategorySettings',
  CategoryId: 'CategoryId',
  AllAccountsSettingStatus: 'AllAccountsSettingStatus',
  AllowContactPreference: 'AllowContactPreference',
  ContactPreferenceEmail: 'ContactPreferenceEmail',
  ContactPreferencePhone: 'ContactPreferencePhone',
  ContactPreferenceMail: 'ContactPreferenceMail',
  MarketingPreference: 'MarketingPreference',
  EmailFormat: 'EmailFormat',
  ChangedAccountList: 'ChangedAccountList',
};

export const AllAccountsSettingStatus = {
  Off: 0,
  On: 1,
  Custom: 2,
};
