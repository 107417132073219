const prodEnvs = [
  'ads.microsoft.com',
  'ui.ads.microsoft.com',
  'ui.bingads.microsoft.com',
  'bingads.microsoft.com',
];

export const getFailFastSetting = () => {
  const hostname = window && window.location && window.location.hostname;
  const href = (window && window.location && window.location.href) || '';
  const isBypassFailFasti18nEnabled = href.includes('isBypassFailFasti18nEnabled=true');

  if (isBypassFailFasti18nEnabled) {
    return 'SAFE';
  }

  if (hostname && prodEnvs.indexOf(hostname.toLowerCase()) > -1) {
    return 'SAFE';
  }
  return true;
};
