import _ from 'underscore';

import {
  ABLVerificationStatus,
  ABLVerificationStatusTextMapping,
  AIVStatusLabelPropertyMapping,
  AccountInactiveCauseCode,
  AccountToAblVerificationStatusRelation,
  AccountVerificationStatus as AccountVerificationStatusEnum,
  AivAppealStatus,
  CNCompositeVerificationStatusTextMapping,
  OrderedUnableTriggerAivReasons,
  UnableTriggerAivReason,
} from './constants';
import { normalizeAddress } from './abl-utils';

/**
 * Get AIV status label using new i18n.
 * @param {object} i18n i18n object
 * @param {number} AIVStatus AIV status value
 * @returns {string} AIV status label
 */
export const getAccountAIVStatusForUI = ({ i18n, accountVerificationStatus, isInternal = false }) => {
  const AIVLabelProperty = AIVStatusLabelPropertyMapping[accountVerificationStatus];
  const labelKey = AIVLabelProperty && (isInternal ? AIVLabelProperty.labelKeyInternal : AIVLabelProperty.labelKey);
  const label = labelKey ? i18n.getString(labelKey) : i18n.getString(_TL_('(n/a)'));
  return {
    ...AIVLabelProperty,
    label,
  };
};

export const getABLVerificationStatusForUI = ({ i18n, ablVerificationStatus }) => {
  const statusLabel = ABLVerificationStatusTextMapping[ablVerificationStatus];
  const notApplicableText = _TL_('(n/a)');
  return {
    label: i18n.getString(statusLabel || notApplicableText),
  };
};

export const getCompositeVerificationStatusForUI = ({ i18n, compositeVerificationStatus }) => {
  const statusLabel = CNCompositeVerificationStatusTextMapping[compositeVerificationStatus];
  const notApplicableText = _TL_('(n/a)');
  return {
    label: i18n.getString(statusLabel || notApplicableText),
  };
};

export const isAccountABLVerificationStatusMismatch = ({ accountVerificationStatus, ablVerificationStatus }) => {
  const ablVerificationStatusMatchList = AccountToAblVerificationStatusRelation[accountVerificationStatus];
  return _.values(ABLVerificationStatus).includes(ablVerificationStatus) && _.isArray(ablVerificationStatusMatchList) && !ablVerificationStatusMatchList.includes(ablVerificationStatus);
};

export const isAccountVerificationStatusExists = accountVerificationStatus => _.contains(_.values(AccountVerificationStatusEnum), accountVerificationStatus);

export const isAccountVerificationRequestSubmitted = accountVerificationStatus => isAccountVerificationStatusExists(accountVerificationStatus) && !_.contains([AccountVerificationStatusEnum.NoNeedVerified, AccountVerificationStatusEnum.NeedVerified], accountVerificationStatus);

export const isInPilot = ({ AccountVerificationStatus }) => isAccountVerificationStatusExists(AccountVerificationStatus) && AccountVerificationStatus !== AccountVerificationStatusEnum.NoNeedVerified;

export const isABLVerificationStatusExists = ablVerificationStatus => _.contains(_.values(ABLVerificationStatus), ablVerificationStatus);

export const findABLVerificationStatus = ({
  addressList, businessName, countryCode, addressData, checkFullAddress, defaultStatus = ABLVerificationStatus.NotVerified,
}) => {
  const targetAddressInfo = checkFullAddress
    ? normalizeAddress({
      ...addressData.address,
      BusinessName: businessName,
    })
    : { BusinessName: businessName.toLowerCase(), Country: countryCode.toLowerCase() };
  const normalizeAddressList = _.map(addressList, address => ({ ...normalizeAddress(address), ABLVerificationStatus: address.ABLVerificationStatus }));
  const { ABLVerificationStatus: ablVerificationStatus } = _.findWhere(normalizeAddressList, targetAddressInfo) || {};
  return isABLVerificationStatusExists(ablVerificationStatus) ? ablVerificationStatus : defaultStatus;
};

export const isAivHold = ({ AccountInactiveReasonsV1 } = {}) =>
  !_.isEmpty(AccountInactiveReasonsV1) && _.contains(AccountInactiveReasonsV1.map(item => item.Causes[0]), AccountInactiveCauseCode.AccountVerificationHold);

export const isUnableTriggerAivReasonExists = unableTriggerAivReason => _.contains(_.values(UnableTriggerAivReason), unableTriggerAivReason);

export const findUnableTriggerAivReason = unableTriggerAivReasons =>
  _.find(OrderedUnableTriggerAivReasons, orderedReason => isUnableTriggerAivReasonExists(orderedReason) && _.contains(unableTriggerAivReasons, orderedReason));

export const isInitializeInProgress = unableTriggerAivReasons => _.contains(unableTriggerAivReasons, UnableTriggerAivReason.InitializeInProgress);

/**
 * Check if the user is unable to trigger email verification. It should align with MT logic.
 * 1. User is internal; 2. User is not super admin; 3. User's parent customer id is not cid in url.
 * @param {object} userContext get from userContext(UserContext)
 * @param {cid} cid Customer id in Url
 * @returns {bool} if the user is unable to retrigger email verification
 */
export const isUnableToRetriggerBusinessVerification = ({ userContext, cid }) => {
  const currentUser = _.get(userContext, 'CurrentUser');
  return !currentUser || currentUser.IsInternal || currentUser.ParentCustomerId !== cid || !currentUser.IsSuperAdmin;
};

export const getFullName = (individualDetailInfo) => {
  const { firstName = '', lastName = '' } = individualDetailInfo || {};
  const separator = _.isEmpty(firstName) || _.isEmpty(lastName) ? '' : ' ';
  return `${firstName}${separator}${lastName}`;
};

export const isAivAppealAllowed = ({ accountVerificationStatus, accountVerificationAppealInfo = {} }) => accountVerificationStatus === AccountVerificationStatusEnum.Failed
  && (accountVerificationAppealInfo.AppealUCMTicket === null || accountVerificationAppealInfo.RemainingRetryCount === 1);

export const isAivAppealSubmitted = ({ accountVerificationStatus, accountVerificationAppealInfo = {} }) => accountVerificationStatus === AccountVerificationStatusEnum.Failed
  && accountVerificationAppealInfo.AppealUCMTicket !== null && accountVerificationAppealInfo.RemainingRetryCount === 0;

export const isAivAppealFailed = ({ accountVerificationStatus, accountVerificationAppealInfo = {} }) => accountVerificationStatus === AccountVerificationStatusEnum.OverrideFailed
  && accountVerificationAppealInfo.AppealUCMTicket !== null;

export const getAivAppealStatus = ({ accountVerificationStatus, accountVerificationAppealInfo = {} }) => {
  if (isAivAppealAllowed({ accountVerificationStatus, accountVerificationAppealInfo })) {
    return AivAppealStatus.Allowed;
  }
  if (isAivAppealSubmitted({ accountVerificationStatus, accountVerificationAppealInfo })) {
    return AivAppealStatus.Submitted;
  }
  if (isAivAppealFailed({ accountVerificationStatus, accountVerificationAppealInfo })) {
    return AivAppealStatus.Failed;
  }
  return AivAppealStatus.InValid;
};
