import { available, scope } from './common';
import { Scope } from '../../shared';

const accountLevelScopes = scope.filter(_scope => _scope !== Scope.Customer);

export const targetingPlacements = {
  id: 'custom-reports-category-targeting-placements',
  name: _TL_('Targeting and Placements'),
  scope,
  available,
  children: [
    {
      id: 'customreports-weburlreport',
      name: _TL_('Website URL (publisher)'),
      // Duplicate
      title: _TL_('See performance data for both search and content websites where your ads appear.'),
      fixedRoute: 'customreports/create/weburlreport',
      route: 'customreports/create/weburlreport',
      searchAlias: _TL_('Website URL (publisher) report'),
      scope,
      available,
      apiKey: 'PublisherUsagePerformanceReport',
      description: _TL_('To see if any website URLs aren\'t performing well enough for your campaign or ad group target settings. For example, if ad impressions at those URLs yield a low click-through-rate, then you might decide to exclude those websites from your campaign.'),
    }, {
      id: 'customreports-audiencesreport',
      name: _TL_('Audiences'),
      title: _TL_('See how the ad groups that are associated with remarketing lists are performing.'),
      fixedRoute: 'customreports/create/audiencesreport',
      route: 'customreports/create/audiencesreport',
      searchAlias: _TL_('Audiences report'),
      scope,
      available,
      apiKey: 'AudiencePerformanceReport',
      description: _TL_('To evaluate performance of remarketing campaigns.'),
    }, {
      id: 'customreports-ageandgenderreport',
      name: _TL_('Age and gender'),
      // Duplicate
      title: _TL_('See total impressions and clicks for each campaign or ad group, organized by gender and age group.'),
      fixedRoute: 'customreports/create/ageandgenderreport',
      route: 'customreports/create/ageandgenderreport',
      searchAlias: _TL_('Age and gender report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'AgeGenderAudienceReport',
      description: _TL_('To discover how your campaigns and ad groups are resonating with different age groups and genders.'),
    }, {
      id: 'customreports-genrereport',
      name: _TL_('Genre'),
      // Duplicate
      title: _TL_('Genre.'),
      fixedRoute: 'customreports/create/dealgenrereport',
      route: 'customreports/create/dealgenrereport',
      searchAlias: _TL_('Genre report'),
      scope: accountLevelScopes,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'GenrePerformanceReport',
      description: _TL_('To find out which genre targets have the best ad performance. You can then validate whether your genre targeting is successful and identify opportunities for improvements.'),
      checkPermission: /* istanbul ignore next */  permissions =>
        permissions.dynamic.AIMCampaign && permissions.dynamic.XandrVideoCampaigns && permissions.dynamic.GenreTargets,
    }, {
      id: 'customreports-professionaldemographicsreport',
      name: _TL_('Professional demographics'),
      title: _TL_('See total impressions and clicks for each campaign or ad group, organized by company, industry, and job function.'),
      fixedRoute: 'customreports/create/professionaldemographicsreport',
      route: 'customreports/create/professionaldemographicsreport',
      searchAlias: _TL_('Professional demographics report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'ProfessionalDemographicsAudienceReport',
      description: _TL_('To discover how your campaigns and ad groups are resonating with different companies, industries, and job functions.'),
      checkPermission: /* istanbul ignore next */  permissions =>
        !!permissions.dynamic.LinkedInTargeting || !!permissions.dynamic.AIMCampaign,
    }, {
      id: 'customreports-userlocationreport',
      name: _TL_('User location'),
      // Duplicate
      title: _TL_('See total impressions and clicks for each ad group, organized by the locations where your ads were shown.'),
      fixedRoute: 'customreports/create/userlocationreport',
      route: 'customreports/create/userlocationreport',
      searchAlias: _TL_('User location report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'UserLocationPerformanceReport',
      description: _TL_('To see where your traffic is coming from broken out by the physical location and the location people are searching for. You can then validate whether your location targeting strategy is successful, and identify opportunities to improve.'),
    }, {
      id: 'customreports-geographicreport',
      name: _TL_('Geographic'),
      // Duplicate
      title: _TL_('See total impressions and clicks for each ad group, organized by the locations where your ads were shown.'),
      fixedRoute: 'customreports/create/geographicreport',
      route: 'customreports/create/geographicreport',
      searchAlias: _TL_('Geographic report'),
      scope,
      available,
      apiKey: 'GeographicPerformanceReport',
      description: _TL_('To see where your traffic is coming from: the physical location of the people searching for your ad or the locations people are searching for. You can then validate whether your location targeting strategy is successful and identify opportunities to improve.'),
    },
  ],
};
