import _ from 'underscore';

export const PaymentInstrumentType = Object.freeze({
  Unset: 0,
  CreditCard: 1,
  Invoice: 2,
  Check: 3,
  ElectronicFundsTransfer: 4,
  PayPal: 5,
  ELV: 6,
  OfflinePaymentMethod: 7,
  VBA: 8,
  Boleto: 9,
  UPI: 10,
});

export const PaymentType = Object.freeze({
  Prepay: 'PrePay',
  Postpay: 'PostPay',
});

export const PaymentTypeId = Object.freeze({
  Postpay: 0,
  Prepay: 1,
  Undefined: 2,
});

export const PaymentOption = Object.freeze({
  Threshold: 'Threshold',
  Invoice: 'Monthly Invoice',
  Prepay: 'Prepay',
});

export const PaymentOptionId = Object.freeze({
  Threshold: 1,
  Invoice: 2,
  Prepay: 12,
});

export const OrderedPaymentOptionId = [
  PaymentOptionId.Prepay,
  PaymentOptionId.Threshold,
  PaymentOptionId.Invoice,
];

export const OrderedPaymentOptionEnum = _.chain(PaymentOptionId)
  .keys()
  .sortBy(val => _.indexOf(OrderedPaymentOptionId, PaymentOptionId[val]))
  .value();

/**
 * Map of payment settings (i.e. payment type) to payment options that fall under that
 * payment setting.
 * Note that going forward this should be used less frequently since
 * designers want to move toward a consolidate po-dropdown instead of separate inputs
 * for payment setting and payment option.
 */
export const PaymentSettingsMap = {
  [PaymentType.Prepay]: [PaymentOptionId.Prepay],
  [PaymentType.Postpay]: [PaymentOptionId.Threshold, PaymentOptionId.Invoice],
};

export const CardCategory = Object.freeze({
  Unknown: 0,
  Debit: 1,
  Credit: 2,
  Prepaid: 3,
});

export const CreditCardType = Object.freeze({
  VISA: 1,
  MC: 2,
  AMEX: 3,
  DISCOVER: 4,
  DINER: 5,
  JCB: 6,
  CB: 7,
  JAL: 8,
  INV: 9,
});

export const PaymentInstrFinancialStatus = Object.freeze({
  NoHold: 0,
  CreditHold: 1,
  ManualHold: 2,
  AllHold: 3,
});

export const PaymentInstrLifecycleStatus = Object.freeze({
  Active: 50,
  Inactive: 51,
  Deleted: 52,
  PendingValidation: 189,
});

export const PaymentTransactionStatus = Object.freeze({
  Unknown: 0,
  Processing: 1,
  Declined: 2,
  Success: 3,
  Chargeback: 4,
  RefundReversal: 5,
  ChargebackReversal: 6,
});

export const SAPApplicationType = Object.freeze({
  ApplyNewBilltoSAP: 0,
  UpdateSAPDetails: 1,
  AddingExistingSAP: 2,
  ApplyNewSoldtoSAP: 3,
});

export const SAPApplicationStatus = Object.freeze({
  UnderReview: 0,
  Approved: 1,
  Rejected: 2,
  ActionRequired: 3,
});

export const SAPApplicationQualificationErrors = Object.freeze({
  NoBillingDocumentAsBillto: 'NoBillingDocumentAsBillto',
  MinSpendBelowThreshold: 'MinSpendBelowThreshold',
});

export const AllowedAgencyPITypes = Object.freeze([
  PaymentInstrumentType.CreditCard,
  PaymentInstrumentType.Invoice,
  PaymentInstrumentType.PayPal,
]);

export const ESCPrepayUnsupportedPITypes = Object.freeze([
  PaymentInstrumentType.ELV,
  PaymentInstrumentType.Boleto,
  PaymentInstrumentType.OfflinePaymentMethod,
]);

export const RiskControlEventTypes = Object.freeze({
  AddFunds: 0,
  Chargeback: 1,
});

export const RiskControlResponseTypes = Object.freeze({
  RiskControlPassed: 0,
  BoletoAddFundsHighRisk: 1,
  SEPAAddFundsHighRisk: 2,
  SEPAChargebackHighRisk: 3,
});

export const NukedPIStatusTypes = Object.freeze({
  ValidNoButton: 0,
  ValidShowButton: 1,
  Invalid: 2,
  AltInvalid: 3,
});

export const CreditCardTypeString = Object.freeze({
  [CreditCardType.VISA]: _TL_('Visa'),
  [CreditCardType.MC]: _TL_('MasterCard'),
  [CreditCardType.AMEX]: _TL_('American Express'),
  [CreditCardType.DISCOVER]: _TL_('Discover'),
  [CreditCardType.DINER]: _TL_('Diners Club'),
  [CreditCardType.JCB]: _TL_('JCB'),
  [CreditCardType.CB]: _TL_('Carte Blanche'),
  [CreditCardType.JAL]: _TL_('JAL'),
  [CreditCardType.INV]: _TL_('Invoice (SAP)'),
});

export const PaymentOptionString = Object.freeze({
  [PaymentOptionId.Threshold]: _TL_('Postpay threshold'),
  [PaymentOptionId.Invoice]: _TL_('Monthly invoice'),
  [PaymentOptionId.Prepay]: _TL_('Prepay'),
});
