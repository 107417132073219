import { available, scope } from './common';
import { Scope } from '../../shared';

const accountLevelScopes = scope.filter(_scope => _scope !== Scope.Customer);

export const performance = {
  id: 'customreports-category-performance',
  name: _TL_('Performance'),
  title: _TL_('Performance reports give you a closer look at how your campaigns are performing, including details like CTR and impressions.'),
  scope,
  available,
  children: [
    {
      id: 'customreports-accountreport',
      name: _TL_('Account'),
      title: _TL_('Understand account performance and identify trends.'),
      fixedRoute: 'customreports/create/accountreport',
      route: 'customreports/create/accountreport',
      searchAlias: _TL_('Account report'),
      scope,
      available,
      apiKey: 'AccountPerformanceReport',
      description: _TL_('To observe long-term account performance and trends.'),
    }, {
      id: 'customreports-campaignreport',
      name: _TL_('Campaign'),
      title: _TL_('See a snapshot of your account, and if there\'s anything to flag.'),
      fixedRoute: 'customreports/create/campaignreport',
      route: 'customreports/create/campaignreport',
      searchAlias: _TL_('Campaign report'),
      scope,
      available,
      apiKey: 'CampaignPerformanceReport',
      description: _TL_('To view high-level performance statistics and quality attributes for each campaign or account. This is also a quick way to flag any major campaign or account problems.'),
    }, {
      id: 'customreports-adgroupreport',
      name: _TL_('Ad group'),
      title: _TL_('See if your ad group is meeting your preset goals.'),
      fixedRoute: 'customreports/create/adgroupreport',
      route: 'customreports/create/adgroupreport',
      searchAlias: _TL_('Ad group report'),
      scope,
      available,
      apiKey: 'AdGroupPerformanceReport',
      description: _TL_('To more broadly compare delivery performance statistics by ad group, campaign, or account attributes rather than at the keyword level.'),
    }, {
      id: 'customreports-adreport',
      name: _TL_('Ad'),
      title: _TL_('See which ads are leading to clicks and conversions.'),
      fixedRoute: 'customreports/create/adreport',
      route: 'customreports/create/adreport',
      searchAlias: _TL_('Ad report'),
      scope,
      available,
      apiKey: 'AdPerformanceReport',
      description: _TL_('To help you determine which ads lead to clicks and conversions, and which are not performing. Having underperforming ads in your account can pull down the quality of your campaigns.'),
    }, {
      id: 'customreports-keywordreport',
      name: _TL_('Keyword'),
      title: _TL_('See which keywords are leading to clicks.'),
      fixedRoute: 'customreports/create/keywordreport',
      route: 'customreports/create/keywordreport',
      searchAlias: _TL_('Keyword report'),
      scope,
      available,
      apiKey: 'KeywordPerformanceReport',
      description: _TL_('To find out which keywords are triggering your ads and getting clicks. You can also identify keywords that aren\'t performing well to determine if you want to delete them.'),
    }, {
      id: 'customreports-negativekeywordconflictreport',
      name: _TL_('Negative keyword conflicts'),
      title: _TL_('See a set of keyword and negative keyword pairs that are blocking your bids from participating in the auction.'),
      fixedRoute: 'customreports/create/negativekeywordconflictreport',
      route: 'customreports/create/negativekeywordconflictreport',
      searchAlias: _TL_('Negative keyword conflicts report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'NegativeKeywordConflictReport',
      description: _TL_('This report tells you which keywords and negative keywords are in conflict, and whether the conflict is at the campaign or ad group level. Use this list to figure out which negative keywords to delete.'),
    }, {
      id: 'customreports-searchtermreport',
      name: _TL_('Search term'),
      // Duplicate
      title: _TL_('See which search terms are causing your ads to display.'),
      fixedRoute: 'customreports/create/searchtermreport',
      route: 'customreports/create/searchtermreport',
      searchAlias: _TL_('Search term report'),
      scope,
      available,
      apiKey: 'SearchQueryPerformanceReport',
      description: _TL_('To see what your audience is searching for when your ads are shown. You can use this information to make informed additions, removals, or edits to both your keyword and negative keyword lists.'),
    }, {
      id: 'customreports-msxaccountperformancereport',
      name: _TL_('MSX account performance'),
      title: _TL_('Access and review daily revenue trends.'),
      fixedRoute: 'customreports/create/msxaccountperformancereport',
      route: 'customreports/create/msxaccountperformancereport',
      searchAlias: _TL_('MSX account performance report'),
      scope,
      available,
      apiKey: 'MSXAccountPerformanceReport',
      description: _TL_('To access and review daily revenue trends.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        permissions.IsMSXAccountPerformanceReportEnabled,
    }, {
      id: 'customreports-SearchInsightPerformanceReport',
      name: _TL_('Search terms insights'),
      // Duplicate
      title: _TL_('See which search insight are causing your ads to display.'),
      fixedRoute: 'customreports/create/SearchInsightPerformanceReport',
      route: 'customreports/create/SearchInsightPerformanceReport',
      searchAlias: _TL_('Search terms insights report'),
      scope: accountLevelScopes,
      available,
      apiKey: 'SearchInsightPerformanceReport',
      description: _TL_('To gain valuable insights into how your customers search for your business. These insights analyze the specific search terms that triggered your ads during a selected time frame. The terms are then grouped into relevant search categories, providing key performance metrics for each category. Only Performance Max campaigns are supported at this time.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        permissions.dynamic.SearchInsightPerformanceReport,
    }, {
      id: 'customreports-CombinationPerformanceReport',
      name: _TL_('Combination performance'),
      title: _TL_('Identify the most frequently occurring asset combinations and provide a view of your combination performance.'),
      fixedRoute: 'customreports/create/CombinationPerformanceReport',
      route: 'customreports/create/CombinationPerformanceReport',
      searchAlias: _TL_('Combination performance report'),
      scope,
      available,
      apiKey: 'CombinationPerformanceReport',
      description: _TL_('To identify the most frequently occurring asset combinations and provide a view of your combination performance.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        permissions.dynamic.CombinationReport,
    },
  ],
};
