import { available, scope } from './common';

export const assetsExtensions = {
  id: 'customreports-category-extensions',
  name: _TL_('Assets and Extensions'),
  scope,
  available,
  children: [
    {
      id: 'customreports-extensionkeywordreport',
      name: _TL_('Ad extension by keyword'),
      title: _TL_('See how your extensions perform for your keywords.'),
      fixedRoute: 'customreports/create/extensionkeywordreport',
      route: 'customreports/create/extensionkeywordreport',
      searchAlias: _TL_('Ad extension by keyword report'),
      scope,
      available,
      apiKey: 'AdExtensionByKeywordReport',
      description: _TL_('To understand how different click types are performing for each keyword when different ad extensions are shown in the ad.'),
    }, {
      id: 'customreports-extensionadreport',
      name: _TL_('Ad extension by ad'),
      title: _TL_('See how your extensions perform for your ads.'),
      fixedRoute: 'customreports/create/extensionadreport',
      route: 'customreports/create/extensionadreport',
      searchAlias: _TL_('Ad extension by ad report'),
      scope,
      available,
      apiKey: 'AdExtensionByAdReport',
      description: _TL_('To understand how different click types are performing for each ad when the ad is served with different ad extensions.'),
    }, {
      id: 'customreports-adextensiondetailreport',
      name: _TL_('Ad extension details'),
      title: _TL_('See how engaging your ad extension items are.'),
      fixedRoute: 'customreports/create/adextensiondetailreport',
      route: 'customreports/create/adextensiondetailreport',
      searchAlias: _TL_('Ad extension details report'),
      scope,
      available,
      apiKey: 'AdExtensionDetailReport',
      description: _TL_('To understand how different ad extension properties are performing when different ad extensions are shown in the ad.'),
    }, {
      title: _TL_('See duration and phone spend details for each forwarded call.'),
      id: 'customreports-callforwarddetailreport',
      // Duplicate
      name: _TL_('Call forwarding detail'),
      fixedRoute: 'customreports/create/callforwarddetailreport',
      route: 'customreports/create/callforwarddetailreport',
      searchAlias: _TL_('Call forwarding detail report'),
      scope,
      available,
      apiKey: 'CallDetailReport',
      description: _TL_('To understand how the accounts, campaigns, or ad groups are performing with call extensions.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        !!permissions.dynamic.CallMetering,
    }, {
      id: 'customreports-assetperformancereport',
      name: _TL_('Asset performance'),
      title: _TL_('View the performance of your search and Performance Max assets for each account, campaign, or ad group.'),
      fixedRoute: 'customreports/create/assetperformancereport',
      route: 'customreports/create/assetperformancereport',
      searchAlias: _TL_('Asset performance report'),
      scope,
      available,
      apiKey: 'AssetPerformanceReport',
      description: _TL_('To view the performance of your search and Performance Max assets for each account, campaign, or ad group.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        permissions.dynamic.DefaultAssetReport,
    }, {
      id: 'customreports-addynamictextreport',
      name: _TL_('Ad dynamic text'),
      title: _TL_('See which ad text strings are doing well.'),
      fixedRoute: 'customreports/create/addynamictextreport',
      route: 'customreports/create/addynamictextreport',
      searchAlias: _TL_('Ad dynamic text report'),
      scope,
      available,
      apiKey: 'AdDynamicTextPerformanceReport',
      description: _TL_('To identify which dynamic text strings are performing well and which strings you should consider changing.'),
    },
  ],
};
