import { available, scope } from './common';
import { Scope } from '../../shared';

const accountLevelScopes = scope.filter(_scope => _scope !== Scope.Customer);

export const verticalAds = {
  id: 'custom-reports-category-vertical-ads',
  name: _TL_('Ad Type: Vertical Ads'),
  scope,
  available,
  children: [
    {
      id: 'customreports-feeditemreport',
      name: _TL_('Feed item'),
      title: _TL_('See performance on feed item level.'),
      fixedRoute: 'customreports/create/feeditemreport',
      route: 'customreports/create/feeditemreport',
      searchAlias: _TL_('Feed item report'),
      scope,
      available,
      apiKey: 'FeedItemPerformanceReport',
      description: _TL_('To view detailed performance statistics for each individual item from the vertical ads inventory including the deleted and inactive Dynamic Data Feed items.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        permissions.dynamic.ReportingUpliftFeedItemReport,
    },
  ],
};
