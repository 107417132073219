import { Scope } from '../../shared';

export const customerAcquisition = {
  id: 'customer-acquisition',
  name: _TL_('Customer acquisition'),
  oldUIScope: [Scope.Account],
  scope: [Scope.Account],
  children: [{
    id: 'customer-acquisition',
    name: _TL_('Customer acquisition'),
    route: 'customeracquisition',
    checkPermission: permissions => permissions.dynamic.NewCustomerAcquisition,
  }],
};
