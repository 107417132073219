import _ from 'underscore';
import { StringUtils } from '@bingads-webui-clientcenter/common-utils';

import { TaxLabelFormat, TaxType, TaxOptionType, TaxTypeEnumValue, TaxIdMaxLength, TaxSGGSTNumberRegExp, TaxNZGSTNumberRegExp, IndiaEInvoicingStatus,
  TaxINPANNumberRegExp, TaxRSPIBNumberRegExp, TaxRSLegalNumberRegExp, TaxINGSTINRegExp, TaxCOVatNumberRegExp, TaxAUGSTNumberRegExp, TaxCOCertificateExecutionDateRegExp,
  TaxTHVATRegExp, TaxNGVATRegExp, TaxCAGSTNumberRegExp, TaxCABCPSTNumberRegExp, TaxCAQCQSTNumberRegExp, TaxCASKPSTNumberRegExp, TaxCAMBPSTNumberRegExp, TaxChileVATRegExp, TaxIERegExp, CountryCode, TaxExemptionCertVerifiedResult, AttestationValue,
  TaxAEVatNumberRegExp, TaxInfo } from './constants';
import { findCountry, isVatApplicable, isTaxableCountry } from './country-utils';

const { isStringNotBlank, regexTest } = StringUtils;

const taxInfoConnector = '-';
const taxInfoSeparator = ', ';

const TaxDocumentStatusI18nMap = {
  Available: _TL_('Download'),
  Pending: _TL_('Pending'),
  Cancelled: _TL_('Canceled'),
  Failed: _TL_('Failed'),
};
const inlineFormatLocalizedTaxInfo = ({ label, value }) => (_.isEmpty(label) || _.isEmpty(value) ? '' : `${label}${taxInfoConnector}${value}`);
const indiaEInvoicingStatusKey = (status) => {
  if (!_.isNumber(status)) {
    return null;
  }

  switch (status) {
    case IndiaEInvoicingStatus.Available:
    case IndiaEInvoicingStatus.PendingCustomerAction:
    case IndiaEInvoicingStatus.PendingSupport:
    case IndiaEInvoicingStatus.PendingCreditAndRebill:
      return 'Available';
    case IndiaEInvoicingStatus.Failed:
      return 'Failed';
    case IndiaEInvoicingStatus.NotStarted:
    case IndiaEInvoicingStatus.PendingIRNGeneration:
    default:
      return 'Pending';
  }
};

/**
 * TaxInfoKeyValue object
 * @typedef {object} TaxInfoKeyValue
 * @property {string} [Key] the Key of the TaxInfoKeyValue corresponding to the TaxInfo type
 * @property {string} [Value] the Value of the TaxInfoKeyValue
 */

/**
 * LocalizedTaxInfo object
 * @typedef {object} LocalizedTaxInfo
 * @property {string} [label] the localized label of the TaxInfo
 * @property {string} [value] the Value of the TaxInfo
 * @returns {LocalizedTaxInfo} returns a proper TaxInfo object localized ready for use in UI
*/
const getTaxTypeToLabelMap = ({ permissions }) => ({
  default: {
    [TaxType.CPF]: _TL_('CPF'),
    [TaxType.CNPJ]: _TL_('CNPJ'),
    [TaxType.GSTINNumber]: _TL_('GST IN'),
    [TaxType.PANNumber]: _TL_('PAN'),
    [TaxType.VatNumber]: _TL_('VAT'),
    [TaxType.AUGSTNumber]: _TL_('Australian Business Number'),
    [TaxType.NZGSTNumber]: _TL_('NZ GST registration number'),
    [TaxType.CCM]: _TL_('C.C.M.'),
    [TaxType.IE]: _TL_('IE'),
    [TaxType.GSTNumber]: _TL_('GST/HST registration number or Business Number'),
    [TaxType.PSTNumber]: _TL_('PST registration number'),
    [TaxType.QSTNumber]: _TL_('QST registration number'),
    [TaxType.LegalIdentifier]: _TL_('Legal Identifier Number'),
  },
  [CountryCode.CO]: {
    [TaxType.VatNumber]: _TL_('NIT/Tax ID'),
  },
  [CountryCode.CL]: {
    [TaxType.VatNumber]: _TL_('RUT Number'),
  },
  [CountryCode.AE]: {
    [TaxType.VatNumber]: _TL_('TRN or VAT ID'),
  },
  [CountryCode.IL]: {
    [TaxType.VatNumber]: _TL_('VAT ID'),
  },
  [CountryCode.NO]: {
    [TaxType.VatNumber]: _TL_('VAT ID'),
  },
  [CountryCode.VN]: {
    [TaxType.VatNumber]: _TL_('TIN'),
  },
  [CountryCode.TR]: {
    [TaxType.VatNumber]: _TL_('VKN'),
  },
  [CountryCode.GE]: {
    [TaxType.VatNumber]: _TL_('VAT ID'),
  },
  [CountryCode.TH]: {
    [TaxType.VatNumber]: _TL_('VAT ID'),
  },
  [CountryCode.BD]: {
    [TaxType.VatNumber]: _TL_('BIN'),
  },
  [CountryCode.ID]: {
    [TaxType.VatNumber]: _TL_('NPWP'),
  },
  [CountryCode.BY]: {
    [TaxType.VatNumber]: _TL_('VAT ID'),
  },
  [CountryCode.NG]: {
    [TaxType.VatNumber]: _TL_('VAT ID'),
  },
  [CountryCode.IS]: {
    [TaxType.VatNumber]: _TL_('VAT ID'),
  },
  [CountryCode.MD]: {
    [TaxType.VatNumber]: _TL_('VAT ID'),
  },
  [CountryCode.ZW]: {
    [TaxType.VatNumber]: _TL_('VAT ID'),
  },
  [CountryCode.BH]: {
    [TaxType.VatNumber]: _TL_('VAT ID'),
  },
  [CountryCode.KH]: {
    [TaxType.VatNumber]: _TL_('VAT-TIN'),
  },
  [CountryCode.KE]: {
    [TaxType.VatNumber]: _TL_('VAT ID'),
  },
  [CountryCode.SA]: {
    [TaxType.VatNumber]: _TL_('TIN or VAT ID'),
  },
  [CountryCode.OM]: {
    [TaxType.VatNumber]: _TL_('VAT ID'),
  },
  [CountryCode.GH]: {
    [TaxType.VatNumber]: _TL_('TIN'),
  },
  [CountryCode.CM]: {
    [TaxType.VatNumber]: _TL_('VAT ID'),
  },
  [CountryCode.UZ]: {
    [TaxType.VatNumber]: _TL_('VAT ID'),
  },
  [CountryCode.FJ]: {
    [TaxType.VatNumber]: _TL_('TIN'),
  },
  [CountryCode.GT]: {
    [TaxType.VatNumber]: _TL_('NIT'),
  },
  [CountryCode.BS]: {
    [TaxType.VatNumber]: _TL_('TIN'),
  },
  [CountryCode.PH]: {
    [TaxType.VatNumber]: _TL_('TIN'),
  },
  [CountryCode.AM]: {
    [TaxType.VatNumber]: _TL_('AAH'),
  },
  [CountryCode.BB]: {
    [TaxType.VatNumber]: _TL_('TIN'),
  },
  [CountryCode.KZ]: {
    [TaxType.VatNumber]: _TL_('NDS/KKS'),
  },
  [CountryCode.MX]: {
    [TaxType.VatNumber]: _TL_('RFC'),
  },
  [CountryCode.ZA]: {
    [TaxType.VatNumber]: _TL_('VAT ID'),
  },
  [CountryCode.LA]: {
    [TaxType.VatNumber]: _TL_('TIN'),
  },
  [CountryCode.NP]: {
    [TaxType.VatNumber]: _TL_('VAT ID'),
  },
  [CountryCode.RS]: {
    [TaxType.VatNumber]: _TL_('Tax identification number (PIB)'),
    [TaxType.LegalIdentifier]: _TL_('Legal number'),
  },
  [CountryCode.AR]: {
    [TaxType.VatNumber]: _TL_('CUIT'),
  },
  [CountryCode.AZ]: {
    [TaxType.VatNumber]: _TL_('VÖEN'),
  },
  [CountryCode.BJ]: {
    [TaxType.VatNumber]: _TL_('IFU'),
  },
  [CountryCode.CN]: {
    [TaxType.VatNumber]: _TL_('Tax ID'),
  },
  [CountryCode.CI]: {
    [TaxType.VatNumber]: _TL_('n° TVA'),
  },
  [CountryCode.JP]: {
    [TaxType.VatNumber]: _TL_('JCT'),
  },
  [CountryCode.MY]: {
    [TaxType.VatNumber]: _TL_('SST'),
  },
  [CountryCode.MM]: {
    [TaxType.VatNumber]: _TL_('TBC'),
  },
  [CountryCode.SR]: {
    [TaxType.VatNumber]: _TL_('FIN'),
  },
  [CountryCode.TJ]: {
    [TaxType.VatNumber]: _TL_('TIN'),
  },
  [CountryCode.UA]: {
    [TaxType.VatNumber]: _TL_('VAT Reg. No.'),
  },
  [CountryCode.VE]: {
    [TaxType.VatNumber]: _TL_('RIF'),
  },
  [CountryCode.ZM]: {
    [TaxType.VatNumber]: _TL_('TPIN'),
  },
  [CountryCode.EG]: {
    [TaxType.VatNumber]: _.get(permissions, ['dynamic', 'ABLEgyptTaxUpdate'], false) ? _TL_('TRN') : _TL_('VAT ID'),
    [TaxType.UIN]: _TL_('UIN'),
  },
  [CountryCode.KR]: {
    [TaxType.VatNumber]: _TL_('BRN'),
  },
  [CountryCode.PE]: {
    [TaxType.VatNumber]: _TL_('RUC'),
  },
  [CountryCode.UY]: {
    [TaxType.VatNumber]: _TL_('RUT'),
  },
  [CountryCode.PA]: {
    [TaxType.VatNumber]: _TL_('RUC'),
  },
  [CountryCode.PK]: {
    [TaxType.VatNumber]: _TL_('STRN'),
  },
  [CountryCode.TW]: {
    [TaxType.VatNumber]: _TL_('VAT ID'),
  },
});

const TaxInfoLabels = {
  [TaxInfo.IsTaxExemptionCertVerified]: {
    [TaxExemptionCertVerifiedResult.Accept]: _TL_('VAT Exempted'),
    [TaxExemptionCertVerifiedResult.Reject]: _TL_('VAT Not Exempted'),
  },
  [TaxInfo.IsIVAOrVATTaxPayer]: {
    [AttestationValue.True]: _TL_('IVA/VAT Taxpayer'),
    [AttestationValue.False]: _TL_('Not IVA/VAT Taxpayer'),
  },
  [TaxInfo.IsWithholdingTaxExempted]: {
    [AttestationValue.True]: _TL_('WHT Exempted'),
    [AttestationValue.False]: _TL_('WHT Not Exempted'),
  },
  [TaxInfo.ReverseChargeDocumentAttestation]: {
    [AttestationValue.True]: _TL_('Reverse Charge Document Attested'),
    [AttestationValue.False]: _TL_('Reverse Charge Document Not Attested'),
  },
};

/**
 * @param {object} permissions the permissions
 * @returns {object} mapping between TaxType to TaxOption
 */
export const getTaxTypeTaxOptionMap = (permissions = {}) => {
  // return new mapping without CCM if pilot on, since CCM will be optional for both Personal and Business
  if (_.get(permissions, ['dynamic', 'ABLBrazilUpdateTax'], false)) {
    return {
      [TaxType.CPF]: TaxOptionType.Personal,
      [TaxType.CNPJ]: TaxOptionType.Business,
    };
  }

  return {
    [TaxType.CPF]: TaxOptionType.Personal,
    [TaxType.CNPJ]: TaxOptionType.Business,
    [TaxType.CCM]: TaxOptionType.Business,
  };
};

/**
 * VAT Id required EMEA Countries,
 *  - key: countryCode
 *  - value: pilot name, or boolean if the feature has been GAed
 */
const EMEAVatIdRequiredCountries = {
  [CountryCode.HU]: 'ABLHungaryTax',
  [CountryCode.PT]: 'ABLPortugalTax',
};

/**
 * VAT Id required EMEA Countries for B2C
 *  - key: countryCode
 *  - value: pilot name, or boolean if the feature has been GAed
 */
const EMEAVatIdRequiredCountriesForB2C = {
  [CountryCode.HU]: 'ABLHungaryTaxPayments',
  [CountryCode.PT]: 'ABLPortugalTaxPayments',
};

/**
 * Check the EMEA VAT ID required status
 * @function
 * @param {object} param param
 * @property {object} param.countryPilotMapping country pilot mapping object
 * @property {string} param.countryCode country code
 * @property {object} param.permissions permissions object
 * @returns {boolean} `true` if the pilot is enabled or feature has been GAed, otherwise `false`.
 */
export const checkEMEAVatIdRequiredStatus = ({ countryPilotMapping, countryCode, permissions }) => {
  if (_.has(countryPilotMapping, countryCode)) {
    const pilotId = countryPilotMapping[countryCode];

    if (_.isBoolean(pilotId)) {
      return pilotId;
    }

    const { dynamic } = permissions || {};
    return !!(_.result(dynamic, pilotId));
  }

  return false;
};

/**
 * Check if the VatId is required for the countryCode
 * @function
 * @param {object} param param
 * @property {string} param.countryCode country code
 * @property {object} param.permissions permissions object
 * @returns {boolean} `true` if VatId is required for this country, otherwise `false`
 */
export const isEMEAVatIdRequired = ({ countryCode, permissions }) => checkEMEAVatIdRequiredStatus({ countryPilotMapping: EMEAVatIdRequiredCountries, countryCode, permissions });

/**
 * Check if the VatId is required for the countryCode for B2C
 * @function
 * @param {object} param param
 * @property {string} param.countryCode country code
 * @property {object} param.permissions permissions object
 * @returns {boolean} `true` if VatId is required for this country for B2C, otherwise `false`
 */
export const isEMEAVatIdRequiredForB2C = ({ countryCode, permissions }) => checkEMEAVatIdRequiredStatus({ countryPilotMapping: EMEAVatIdRequiredCountriesForB2C, countryCode, permissions });

/**
 * @param {string} taxOption the tax option
 * @param {object} permissions the permissions
 * @returns {string} the tax type
 */
export const getTaxOptionByType = (taxOption, permissions) => {
  const TaxTypeTaxOptionMap = getTaxTypeTaxOptionMap(permissions);
  return TaxTypeTaxOptionMap[taxOption];
};

/**
 * Gets the display string with optional label text
 * @param {string} label of the tax
 * @param {*} i18n the i18n object
 * @returns {string} display string of label with optional text
 */
export const getOptionalLabel = (label, i18n) => (i18n ? `${label} ${i18n.getString(_TL_('(optional)'))}` : label);

/**
 * Gets the display string with state tax id optional label text
 * @param {string} label of the tax
 * @param {*} i18n the i18n object
 * @returns {string} display string of label with optional text
 */
export const getStateTaxIDOptional = (label, i18n) => (i18n ? `${label} ${i18n.getString(_TL_('(State Tax ID, optional)'))}` : label);

/**
 * Gets the display string with required label text
 * @param {string} label of the tax
 * @param {*} i18n the i18n object
 * @returns {string} display string of label with required text
 */
export const getRequiredLabel = (label, i18n) => (i18n ? `${label} ${i18n.getString(_TL_('(required)'))}` : label);

/**
 * Gets the display string with required for all the entities label text
 * @param {string} label of the tax
 * @param {*} i18n the i18n object
 * @returns {string} display string of label with required text
 */
export const getRequiredForAllTheEntitiesLabel = (label, i18n) => (i18n ? `${label} ${i18n.getString(_TL_('(required for all the entities)'))}` : label);

/**
 * Gets the display string with required for business label text
 * @param {string} label of the tax
 * @param {*} i18n the i18n object
 * @returns {string} display string of label with required for business label text
 */
export const getRequiredForBusinessLabel = (label, i18n) => (i18n ? `${label} ${i18n.getString(_TL_('(required for business customers)'))}` : label);

/**
 * Gets the display string with required for VAT registered business customers label text
 * @param {string} label of the tax
 * @param {*} i18n the i18n object
 * @returns {string} display string of label with required for VAT registered business customers label text
 */
export const getRequiredForVATRegisteredBusinessLabel = (label, i18n) => (i18n ? `${label} ${i18n.getString(_TL_('(required for VAT registered business customers)'))}` : label);

export const getRequiredForValueAddedTaxIVARegisteredLabel = (label, i18n) => (i18n ? `${label} ${i18n.getString(_TL_('(required for Value Added Tax/IVA registered customers)'))}` : label);

export const getRequiredForIVAorVATTaxPayerLabel = (label, i18n) => (i18n ? `${label} ${i18n.getString(_TL_('(required for IVA/VAT tax-payers)'))}` : label);

/**
 * Gets the display string of TaxInfo's type from i18n, or empty string if the type is unknown or i18n cannot be found
 * @param {string} taxType the tax type key
 * @param {*} i18n the i18n object
 * @param {string} options options for the label format and country code
 * @param {object} permissions the permissions
 * @returns {string} display string of TaxInfo's type from i18n
 */
export const getTaxTypeLabel = (
  taxType,
  i18n,
  {
    labelFormat = TaxLabelFormat.Default,
    countryCode,
  } = {},
  permissions = {}
) => {
  const taxTypeToLabelMap = getTaxTypeToLabelMap({ permissions });
  const taxTypeLabelKey = _.get(taxTypeToLabelMap, [countryCode, taxType]) || taxTypeToLabelMap.default[taxType];
  const taxTypeLabel = taxTypeLabelKey && i18n ? i18n.getString(taxTypeLabelKey) : '';

  if (!taxTypeLabel) {
    return taxTypeLabel;
  }

  switch (labelFormat) {
    case TaxLabelFormat.Optional:
      return getOptionalLabel(taxTypeLabel, i18n);
    case TaxLabelFormat.Required:
      return getRequiredLabel(taxTypeLabel, i18n);
    case TaxLabelFormat.RequiredForAllTheEntities:
      return getRequiredForAllTheEntitiesLabel(taxTypeLabel, i18n);
    case TaxLabelFormat.RequiredForBusiness:
      return getRequiredForBusinessLabel(taxTypeLabel, i18n);
    case TaxLabelFormat.RequiredForVATRegisteredBusiness:
      return getRequiredForVATRegisteredBusinessLabel(taxTypeLabel, i18n);
    case TaxLabelFormat.RequiredForValueAddedTaxIVARegistered:
      return getRequiredForValueAddedTaxIVARegisteredLabel(taxTypeLabel, i18n);
    case TaxLabelFormat.RequiredForIVAorVATTaxPayer:
      return getRequiredForIVAorVATTaxPayerLabel(taxTypeLabel, i18n);
    case TaxLabelFormat.StateTaxIDOptional:
      return getStateTaxIDOptional(taxTypeLabel, i18n);
    case TaxLabelFormat.Default:
    default:
      return taxTypeLabel;
  }
};

/**
 *
 * @param {string} taxCertLabel - The label for tax cert field
 * @param {bool} taxCertOptional - Indicate whether tax cert is optional
 * @param {object} i18n - the i18n object
 * @returns {string} - Tax cert field label
 */
export const getTaxCertLabelDisplay = (taxCertLabel, taxCertOptional, i18n) => (taxCertOptional ? getOptionalLabel(taxCertLabel, i18n) : taxCertLabel);

/**
 *
 * @param {string} label of tax
 * @param {*} i18n the i18n object
 * @returns {string} display string of label with required for customers claiming a tax exemption
 */
export const getRequiredForCustomerClaimTaxExemption = (label, i18n) => (i18n ? `${label} ${i18n.getString(_TL_('(required for customers claiming a tax exemption)'))}` : label);

/**
 * @param {string} taxType the tax type
 * @returns {string} the tax type enum value
 */
export const getTaxTypeEnumValue = taxType => TaxTypeEnumValue[taxType];

/**
 * Map the TaxInfoKeyValue pair in parameter into a proper TaxInfo object localized ready for use in UI
 * @param {TaxInfoKeyValue} taxInfoKeyValue the TaxInfoKeyValue to map
 * @param {*} i18n the i18n object
 * @param {object} options the option object
 * @returns {LocalizedTaxInfo} returns a proper TaxInfo object localized ready for use in UI
 */
export const mapToLocalizedTaxInfo = (taxInfoKeyValue, i18n, options) => {
  const label = getTaxTypeLabel(taxInfoKeyValue && taxInfoKeyValue.Key, i18n, options);
  const value = (taxInfoKeyValue && taxInfoKeyValue.Value) || '';

  return { label, value };
};

/**
 * Use mapToLocalizedTaxInfo for all TaxInfoKeyValue objects in the taxInfoKeyValues in params
 * @param {TaxInfoKeyValue[]} taxInfoKeyValues the TaxInfoKeyValues array to map
 * @param {*} i18n the i18n object
 * @param {object} options the options object
 * @returns {LocalizedTaxInfo[]} returns a proper TaxInfo object's array localized ready for use in UI
 */
export const mapToLocalizedTaxInfos = (taxInfoKeyValues, i18n, options) => _.chain(taxInfoKeyValues)
  .map(_.partial(mapToLocalizedTaxInfo, _, i18n, options))
  .filter(localizedTaxInfo => !_.isEmpty(localizedTaxInfo.label))
  .value();

/**
 * Mapping between TaxOption to Label
 */
const TaxOptionTypeToLabelMap = {
  [TaxOptionType.Personal]: _TL_('Personal'),
  [TaxOptionType.Business]: _TL_('Business'),
};

/**
 * Gets the display string of TaxOption type from i18n, or empty string if the type is unknown or i18n cannot be found
 * @param {string} taxOption the tax option key
 * @param {*} i18n the i18n object
 * @returns {string} display string of Tax Option from i18n
 */
export const getTaxOptionLabel = (taxOption, i18n) => {
  const taxOptionLabelKey = TaxOptionTypeToLabelMap[taxOption];
  return taxOptionLabelKey && i18n ? i18n.getString(taxOptionLabelKey) : '';
};

export const isPersonalTaxOptionType = taxOption => taxOption === TaxOptionType.Personal;

export const isBusinessTaxOptionType = taxOption => taxOption === TaxOptionType.Business;

/**
 * Returns true if the 2 TaxInfos object in param have the same set of taxInfos, whatever the order.
 * If both arrays are empty, then we consider them equal.
 * @param {TaxInfoKeyValue[]} taxInfos the tax infos array to test for equality, need to have unique set of key
 * @param {TaxInfoKeyValue[]} otherTaxInfos the other tax infos array to test for equality, need to have unique set of key
 * @returns {boolean} true if the 2 TaxInfos object in param are equal, false otherwise.
 * @throws Will throw an error if the taxInfos in param have duplicate Key or no Key property
 */
export const areEqual = (taxInfos, otherTaxInfos) => {
  if (_.isEmpty(taxInfos) && _.isEmpty(otherTaxInfos)) {
    return true;
  }

  if (_.isArray(taxInfos) && _.isArray(otherTaxInfos)) {
    if (taxInfos.length !== otherTaxInfos.length) {
      return false;
    }

    const hasPropertyKeys = taxInfoArray => _.all(taxInfoArray, taxInfo => _.has(taxInfo, 'Key'));
    if (!hasPropertyKeys(taxInfos) || !hasPropertyKeys(otherTaxInfos)) {
      throw new Error('TaxInfos parameters needs to have the property "Key" for comparison');
    }

    const hasUniqueKeys = taxInfoArray => _.unique(taxInfoArray, false, taxInfo => taxInfo.Key).length === taxInfoArray.length;
    if (!hasUniqueKeys(taxInfos) || !hasUniqueKeys(otherTaxInfos)) {
      throw new Error('TaxInfos parameters cannot have duplicate Keys');
    }

    return _.all(taxInfos, taxInfo => _.some(otherTaxInfos, otherTaxInfo => _.isEqual(taxInfo, otherTaxInfo)));
  }

  return false;
};

/**
 * Get the formatted tax document status key used by billing grid tax column
 * @param {string} brazilEnfStatus Brzil enf status.
 * @param {boolean} hasRussiaDAF whether has Russia DAF
 * @param {number} billingDocumentId billing document Id
 * @param {number} indiaEInvoicingStatus india e-invoicing status
 * @return {string} Localization key for the tax doc status.
 */
export const getFormattedTaxDocumentStatusKey = ({
  brazilEnfStatus,
  hasRussiaDAF,
  billingDocumentId,
  indiaEInvoicingStatus,
}) => {
  const status =
    brazilEnfStatus ||
    (indiaEInvoicingStatusKey(indiaEInvoicingStatus)) ||
    (hasRussiaDAF && billingDocumentId && 'Available');

  if (status) {
    return TaxDocumentStatusI18nMap[status];
  }

  return null;
};

/**
 * get the tax document download Url.
 * @param {string} brazilEnfLink the Brazil ENF download link
 * @param {boolean} hasRussiaDAF whether has Russia DAF
 * @param {string} indiaEInvoicingLink the India E-invoicing download link
 * @param {number} billingDocumentId billing document Id
 * @param {number} advertiserCustomerId the advertiser customerId of this BD.
 * @param {number} accountId account id
 * @param {object} urlBuilders the urlBuilders components.
 * @return {string} the download url
 */
export const getTaxDocumentUrl = ({
  brazilEnfLink,
  hasRussiaDAF,
  indiaEInvoicingLink,
  billingDocumentId,
  advertiserCustomerId,
  accountId,
  urlBuilders,
}) => {
  if (brazilEnfLink) {
    return brazilEnfLink;
  }

  if (hasRussiaDAF && billingDocumentId && advertiserCustomerId) {
    return new urlBuilders.BillingUrlBuilder().action('RenderDAFDocument').params({ aid: accountId, id: billingDocumentId, advertiserCustomerId }).url;
  }

  if (indiaEInvoicingLink) {
    return indiaEInvoicingLink;
  }

  return '';
};

/**
 * Return the tax amount and balance amount
 * @param {number} fundingAmount the amount to fund
 * @param {number} estimatedTaxRate the estimated tax amount
 * @param {number} currentBalance the current balance
 * @returns {object} contains the actual applied funding amount and the tax amount
 */
export const getTaxAndBalance = ({ fundingAmount, estimatedTaxRate, currentBalance } = {}) => {
  if (!_.isFinite(fundingAmount) || !_.isFinite(estimatedTaxRate)) {
    return {};
  }

  const actingTaxRate = Math.max(0, estimatedTaxRate);
  const taxAmount = fundingAmount * actingTaxRate;
  const appliedAmount = _.isFinite(taxAmount) && fundingAmount - taxAmount;

  return _.extend({}, { taxAmount, appliedAmount }, _.isFinite(currentBalance) && { finalBalance: currentBalance + appliedAmount });
};

/**
 * Return the total amount which can get the target post tax amount after paying tax
 * @param {number} postTaxAmount the target gross amount after paying tax
 * @param {number} estimatedTaxRate the estimated tax amount
 * @returns {number} the total amount before paying tax
 */
export const calculatePreTaxAmount = ({ postTaxAmount, estimatedTaxRate } = {}) => {
  if (!_.isFinite(postTaxAmount) || !_.isFinite(estimatedTaxRate) || postTaxAmount < 0 || estimatedTaxRate < 0 || estimatedTaxRate >= 1) {
    return null;
  }

  return postTaxAmount / (1 - estimatedTaxRate); // postTaxAmount = preTaxAmount - preTaxAmount * estimatedTaxRate
};

/**
 * Return the tax rate according to the taxing amount and the actual tax
 * @param {number} tax the tax amount
 * @param {number} taxingAmount the funding amount which should pay tax
 * @returns {number} the tax rate
 */
export const getTaxRate = ({ tax, taxingAmount } = {}) => {
  if (!_.isFinite(tax) || !_.isFinite(taxingAmount) || taxingAmount <= 0 || tax < 0 || tax > taxingAmount) {
    return null;
  }

  return tax / taxingAmount;
};

/**
 * Return whether the account is taxable
 * @param {object} account the account
 * @param {array} countries an array of the countries list
 * @returns {bool} indicate whether the account is taxable
 */
export const isAccountTaxable = (account, countries) => {
  if (!account || !countries || !countries.length) {
    return false;
  }

  const { BusinessAddress: { Country: countryCode } = {} } = account;
  const country = findCountry({ countries, countryCode });

  return isTaxableCountry(country) || isVatApplicable(country);
};

/**
 * Return whether the provided CPF id is valid
 * @param {string} cpfId The CPF Id
 * @returns {bool} Indicate whether CPF id is valid format
 */
export const isCPFIdValid = (cpfId) => {
  if (!_.isString(cpfId) || !isStringNotBlank(cpfId) || cpfId.length < TaxIdMaxLength.CPF) {
    return false;
  }

  let v1 = 0;
  let v2 = 0;

  const v1Coefficients = _.range(10, 1, -1);
  const v2Coefficients = _.range(11, 2, -1);
  const getCPFDigit = index => Number(cpfId[index]);

  _.each(_.range(v1Coefficients.length), (index) => {
    const value = getCPFDigit(index);

    v1 += v1Coefficients[index] * value;
    v2 += v2Coefficients[index] * value;
  });

  v1 = 11 - (v1 % 11);
  v1 = v1 >= 10 ? 0 : v1;

  v2 += (2 * v1);
  v2 = 11 - (v2 % 11);
  v2 = v2 >= 10 ? 0 : v2;

  if (v1 !== getCPFDigit(9) || v2 !== getCPFDigit(10)) {
    return false;
  }

  return true;
};

/**
 * Return whether the provided CNPJ id is valid
 * @param {string} cnpjId The CNPJ Id
 * @returns {bool} Indicate whether CNPJ id is valid format
 */
export const isCNPJIdValid = (cnpjId) => {
  if (!_.isString(cnpjId) || !isStringNotBlank(cnpjId) || cnpjId.length < TaxIdMaxLength.CNPJ) {
    return false;
  }

  let v1 = 0;
  let v2 = 0;

  const v1Coefficients = [..._.range(5, 1, -1), ..._.range(9, 1, -1)];
  const v2Coefficients = [..._.range(6, 1, -1), ..._.range(9, 1, -1)];
  const getCNPJDigit = index => Number(cnpjId[index]);

  _.each(v1Coefficients, (v1Digit, index) => { v1 += v1Digit * getCNPJDigit(index); });
  _.each(v2Coefficients, (v2Digit, index) => { v2 += v2Digit * getCNPJDigit(index); });

  v1 = 11 - (v1 % 11);
  v1 = v1 >= 10 ? 0 : v1;

  v2 = 11 - (v2 % 11);
  v2 = v2 >= 10 ? 0 : v2;

  if (v1 !== getCNPJDigit(12) || v2 !== getCNPJDigit(13)) {
    return false;
  }

  return true;
};

/**
 * Return whether the provided CO Vat Number is valid
 * @param {string} coVatNumber The CO Vat Number
 * @returns {bool} Indicate whether CO Vat Number is valid format
 */
export const isCOVatNumberValid = _.partial(regexTest, TaxCOVatNumberRegExp);

/**
 * Return whether the provided AE Vat Number is valid
 * @param {string} aeVatNumber The AE Vat Number
 * @returns {bool} Indicate whether AE Vat Number is valid format
 */
export const isAEVatNumberValid = _.partial(regexTest, TaxAEVatNumberRegExp);

/**
 * Return whether the provided AUD Vat Number is valid
 * @param {string} AUGSTNumber The AUD Vat Number
 * @returns {bool} Indicate whether AUD Vat Number is valid format
 */
export const isAUGSTNumberValid = (AUGSTNumber) => {
  const isAUGSTNumberFormatValid = _.partial(regexTest, TaxAUGSTNumberRegExp);
  if (AUGSTNumber === '') {
    return true;
  }

  if (!isAUGSTNumberFormatValid(AUGSTNumber) || !AUGSTNumber || AUGSTNumber.length !== 11) {
    return false;
  }

  const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
  let sum = 0;

  for (let i = 0; i < AUGSTNumber.length; i += 1) {
    const digit = parseInt(AUGSTNumber.substring(i, i + 1), 10);
    if (i === 0) {
      sum += (digit - 1) * weights[i];
    } else {
      sum += (digit * weights[i]);
    }
  }

  if (sum % 89 !== 0) {
    return false;
  }

  return true;
};

/**
 * Return whether the provided CO certification execution date is valid
 * @param {string} coCertExecutionDate The CO certification execution date
 * @returns {bool} Indicate whether CO certification execution date is in valid format
 */
export const isCOCertExecutionDateValid = _.partial(regexTest, TaxCOCertificateExecutionDateRegExp);

/**
 * Return whether the provided SG GST Number is valid
 * @param {string} sggstNumber The SG GST Number
 * @returns {bool} Indicate whether SG GST Number is valid format
 */
export const isSGGSTNumberValid = _.partial(regexTest, TaxSGGSTNumberRegExp);

/**
 * Return whether the provided New Zealand GST Number is valid
 * @param {string} nzgst The New Zealand GST Number
 * @returns {bool} Indicate whether New Zealand GST Number is valid format
 */
export const isNZGSTNumberValid = _.partial(regexTest, TaxNZGSTNumberRegExp);

/**
 * Return whether the provided India PAN Number is valid
 * @param {string} pan The PAN tax ID number
 * @returns {bool} Indicate whether PAN number is in a valid format
 */
export const isPANNumberValid = _.partial(regexTest, TaxINPANNumberRegExp);

/**
 *  Return whether the provided Serbian PIB number is valid
 * @param {string} pib  The PIB tax ID number
 * @returns {bool} Indicate whether PIB number is in a valid format
 */
export const isPIBNumberValid = _.partial(regexTest, TaxRSPIBNumberRegExp);

/**
 *  Return whether the provided Serbian legal number is valid
 * @param {string} legalNumber  The PIB tax ID number
 * @returns {bool} Indicate whether PIB number is in a valid format
 */
export const isRSLegalNumberValid = _.partial(regexTest, TaxRSLegalNumberRegExp);

/**
 *  Return whether the provided India GSTIN is valid
 * @param {string} gstin The GSTIN tax ID number
 * @returns {bool} Indicate whether GSTIN is in a valid format
 */
export const isIndiaGSTINValid = _.partial(regexTest, TaxINGSTINRegExp);

/**
 * Return whether the provided Thailand VAT is valid
 * @param {string} thvat The Thailand VAT
 * @returns {bool} Indicate whether Thailand VAT is valid format
 */
export const isThailandVATValid = _.partial(regexTest, TaxTHVATRegExp);

/**
 * Return whether the provided Nigeria VAT is valid
 * @param {string} ngvat The Nigeria Vat Number
 * @returns {bool} Indicate whether Nigeria Vat Number is valid format
 */
export const isNigeriaVATValid = _.partial(regexTest, TaxNGVATRegExp);

/**
 * Return whether the provided Canada GST Number is valid
 * @param {string} cagst The Canada GST Number
 * @returns {bool} Indicate whether Canada GST Number is valid format
 */
export const isCanadaBCPSTNumberValid = _.partial(regexTest, TaxCABCPSTNumberRegExp);

/**
 * Return whether the provided Canada GST Number is valid
 * @param {string} cagst The Canada GST Number
 * @returns {bool} Indicate whether Canada GST Number is valid format
 */
export const isCanadaQCQSTNumberValid = _.partial(regexTest, TaxCAQCQSTNumberRegExp);

/**
 * Return whether the provided Canada GST Number is valid
 * @param {string} cagst The Canada GST Number
 * @returns {bool} Indicate whether Canada GST Number is valid format
 */
export const isCanadaMBPSTNumberValid = _.partial(regexTest, TaxCAMBPSTNumberRegExp);

/**
 * Return whether the provided Canada GST Number is valid
 * @param {string} cagst The Canada GST Number
 * @returns {bool} Indicate whether Canada GST Number is valid format
 */
export const isCanadaSKPSTNumberValid = _.partial(regexTest, TaxCASKPSTNumberRegExp);


/**
 * Return whether the provided Canada GST Number is valid
 * @param {string} cagst The Canada GST Number
 * @returns {bool} Indicate whether Canada GST Number is valid format
 */
export const isCanadaGSTNumberValid = _.partial(regexTest, TaxCAGSTNumberRegExp);

/**
 * Return whether the provided IE Vat Number is valid
 * @param {string} IE The Vat Number
 * @returns {bool} Indicate whether IE Vat Number is valid format
 */
export const isIEValid = _.partial(regexTest, TaxIERegExp);

/**
 * Return whether the provided Chile VAT Number is valid
 * @param {string} chileVATNumber The Chile VAT Number
 * @returns {bool} Indicate whether Chile VAT Number is valid format
 */
export const isChileVATValid = (chileVATNumber) => {
  const isChileVATFormatValid = _.partial(regexTest, TaxChileVATRegExp);
  if (chileVATNumber === '') {
    return true;
  }

  if (!isChileVATFormatValid(chileVATNumber)) {
    return false;
  }
  const weights = [2, 3, 4, 5, 6, 7];
  const strList = chileVATNumber.split('-');
  const num = strList[0];
  const checkDigit = strList[1];
  let sum = 0;
  let j = 0;
  for (let i = num.length - 1; i >= 0; i -= 1) {
    sum += (num[i] - '0') * weights[j % weights.length];
    j += 1;
  }
  const expectedCheckDigit = 11 - (sum % 11);
  switch (expectedCheckDigit) {
    case 11:
      return checkDigit === '0';
    case 10:
      return checkDigit === 'K';
    default:
      return checkDigit === expectedCheckDigit.toString();
  }
};

/**
 * Convert the TaxInfoKeyValue pair in parameter to localized inline format
 * @param {TaxInfoKeyValue} taxInfoKeyValue the TaxInfoKeyValue
 * @param {object} i18n the i18n object
 * @returns {string} the localized inline formatted taxInfo
 */
export const inlineFormatTaxInfo = (taxInfoKeyValue, i18n) =>
  inlineFormatLocalizedTaxInfo(mapToLocalizedTaxInfo(taxInfoKeyValue, i18n));

/**
 * Convert all TaxInfoKeyValue objects in the taxInfoKeyValues in params to localized inline format
 * @param {TaxInfoKeyValue[]} taxInfoKeyValues the TaxInfoKeyValues array
 * @param {object} i18n the i18n object
 * @returns {string} the lozalized inline formatted taxInfos
 */
export const inlineFormatTaxInfos = (taxInfoKeyValues, i18n) =>
  _.reduce(mapToLocalizedTaxInfos(taxInfoKeyValues, i18n), (memo, localizedTaxInfo) => {
    const separator = _.isEmpty(memo) ? '' : taxInfoSeparator;
    const inlineformattedTaxInfo = inlineFormatLocalizedTaxInfo(localizedTaxInfo);
    return _.isEmpty(inlineformattedTaxInfo) ? memo : `${memo}${separator}${inlineformattedTaxInfo}`;
  }, '');

/**
 * Get tax value of a given tax type, returns null if not exists
 * @param {TaxInfoKeyValue[]} taxInfoKeyValues - The TaxInfoKeyValues array of { Key: taxType, Value: taxValue }
 * @param {string} taxType - The tax type to find
 * @returns {string} - The tax value, returns null if not exists
 */
export const getTaxValue = (taxInfoKeyValues, taxType) =>
  _.chain(taxInfoKeyValues)
    .findWhere({ Key: taxType })
    .result('Value', null)
    .value();

/**
 * Get available tax types from taxSchema by reading taxSchema.properties
 * @param {object} taxSchema - The taxSchema object containing properties of available tax types
 * @returns {string[]} - The available tax types
 */
export const getAvailableTaxTypes = ({ taxSchema }) =>
  _.filter(_.keys(taxSchema.properties), taxType => _.keys(TaxType).includes(taxType));

/**
 * Fill taxData with empty-Value properties of avaiable tax types if not exist
 * @param {object} taxInfoUIObject - The taxInfo UI object. Property is TaxType, value is TaxValue.
 * @param {object} taxSchema - The taxSchema object containing properties of available tax types
 * @returns {object} - The filled taxInfo UI object
 */
export const defaultsTaxDataWithEmptyValue = ({ taxInfoUIObject, taxSchema }) => {
  const availableTaxTypes = getAvailableTaxTypes({ taxSchema });
  return _.reduce(
    availableTaxTypes,
    (memo, taxType) => ({ ...memo, [taxType]: _.result(taxInfoUIObject, taxType, null) }),
    {}
  );
};

/**
 * Normalize IsTaxExemptionCertVerified value from MT
 * @param {string} MTValue - The MTValue of IsTaxExemptionCertVerified
 * @returns {string} - The valid IsTaxExemptionCertVerified value accepted by MT
 */
export const normalizeTaxExemptionCertVerifiedResult = (MTValue) => {
  if (_.result(MTValue, 'toLowerCase') === TaxExemptionCertVerifiedResult.Accept.toLowerCase()) {
    return TaxExemptionCertVerifiedResult.Accept;
  }

  if (_.result(MTValue, 'toLowerCase') === TaxExemptionCertVerifiedResult.Reject.toLowerCase()) {
    return TaxExemptionCertVerifiedResult.Reject;
  }

  return TaxExemptionCertVerifiedResult.NotSet;
};

/**
 * Normalize attestation value from MT
 * @param {string} MTValue - The MTValue of attestation value, e.g. IsIVAOrVATTaxPayer
 * @returns {string} - The valid attestation value accepted by MT
 */
export const normalizeAttestationValue = (MTValue) => {
  if (_.result(MTValue, 'toLowerCase') === AttestationValue.True.toLowerCase()) {
    return AttestationValue.True;
  }

  if (_.result(MTValue, 'toLowerCase') === AttestationValue.False.toLowerCase()) {
    return AttestationValue.False;
  }

  return AttestationValue.NotSet;
};

// below filters logic is to align with CCMT tax filters in consolidated billing v2
const getTaxFilters = () => {
  const ignoredKeyFilter = item => item.Key !== TaxInfo.SkipVatIdValidation;
  const ignoredKeyValuePairFilter = item => !(item.Key === TaxInfo.IsTaxExemptionCertVerified && normalizeTaxExemptionCertVerifiedResult(item.Value) === TaxExemptionCertVerifiedResult.Reject);
  const emptyValueFilter = item => !_.isEmpty(item.Value) || item.Key === TaxType.CPF;
  return { ignoredKeyFilter, ignoredKeyValuePairFilter, emptyValueFilter };
};

/**
 * Serialize tax information to string
 * @param {array} taxInformation MT tax information
 * @param {*} countryCode country code
 * @param {*} i18n i18n object
 * @returns {string} serialized tax information
 */
export const serializeTaxInformation = (taxInformation = [], countryCode, i18n) => {
  if (taxInformation === null) {
    return i18n.getString(_TL_('No tax number'));
  }
  const delimiter = ', ';
  const { ignoredKeyFilter, ignoredKeyValuePairFilter, emptyValueFilter } = getTaxFilters();
  const taxInformationFiltered = _.chain(taxInformation)
    .filter(ignoredKeyFilter)
    .filter(ignoredKeyValuePairFilter)
    .filter(emptyValueFilter)
    .value();
  return _.reduce(taxInformationFiltered, (acc, taxId) => {
    if (Object.keys(TaxType).includes(taxId.Key)) {
      const taxTypeLabel = getTaxTypeLabel(taxId.Key, i18n, { countryCode });
      const value = taxId.Value || i18n.getString(_TL_('Not set'));
      // eslint-disable-next-line no-param-reassign
      acc += acc ? `${delimiter}${taxTypeLabel}: ${value}` : `${taxTypeLabel}: ${value}`;
    } else if (Object.keys(TaxInfo).includes(taxId.Key)) {
      const taxInfoLabelMap = TaxInfoLabels[taxId.Key] || {};
      const normalizedValue = normalizeAttestationValue(taxId.Value);
      const taxInfoLabel = i18n.getString(taxInfoLabelMap[normalizedValue]);
      // eslint-disable-next-line no-param-reassign
      acc += acc ? `${delimiter}${taxInfoLabel}` : taxInfoLabel;
    }
    return acc;
  }, '');
};

export const isCBV2TaxInformationMatch = (taxInformation1 = [], taxInformation2 = []) => {
  const convertToLowerCase = item => ({ Key: item.Key.toLowerCase(), Value: item.Value.toLowerCase() });
  const { ignoredKeyFilter, ignoredKeyValuePairFilter, emptyValueFilter } = getTaxFilters();
  const taxInformation1Filtered = _.chain(taxInformation1)
    .filter(ignoredKeyFilter)
    .filter(ignoredKeyValuePairFilter)
    .filter(emptyValueFilter)
    .map(convertToLowerCase)
    .sortBy('Key')
    .value();
  const taxInformation2Filtered = _.chain(taxInformation2)
    .filter(ignoredKeyFilter)
    .filter(ignoredKeyValuePairFilter)
    .filter(emptyValueFilter)
    .map(convertToLowerCase)
    .sortBy('Key')
    .value();
  return _.isEqual(taxInformation1Filtered, taxInformation2Filtered);
};
