import { Scope } from '../shared';

export const accountIdentityVerification = {
  id: 'aivStandalone',
  name: _TL_('Advertiser identity verification'),
  children: [
    {
      id: 'accountIdentityVerification',
      name: _TL_('Advertiser identity verification'),
      route: 'account/IdentityVerification',
      scope: [Scope.Account],
    },
    {
      id: 'aivWelcome',
      name: _TL_('Advertiser identity verification welcome'),
      route: 'account/IdentityVerificationWelcome',
      scope: [Scope.Account],
    },
  ],
};
