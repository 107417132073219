import { allScopes, Scope } from '../../shared';

const accountLevelScopes = allScopes.filter(scope => scope !== Scope.Customer);


export const labels = {
  id: 'customreports-labels',
  name: _TL_('Labels'),
  dropdown: 'right',
  scope: allScopes,
  children: [
    {
      id: 'customreports-report-labels-keyword',
      name: _TL_('Labels - Keyword'),
      searchAlias: _TL_('Labels - Keyword report'),
      route: 'customreports/create/labelskeywordreport',
      scope: accountLevelScopes,
      apiKey: 'LabelKeywordReport',
      description: _TL_('See performance data based on keyword labels and see if there are any patterns of performance based on labels.'),
    },
    {
      id: 'customreports-report-labels-ad',
      name: _TL_('Labels - Ad'),
      searchAlias: _TL_('Labels - Ad report'),
      route: 'customreports/create/labelsadreport',
      scope: accountLevelScopes,
      apiKey: 'LabelAdReport',
      description: _TL_('See performance data based on ad labels and see if there are any patterns of performance based on labels.'),
    },
    {
      id: 'customreports-report-labels-ad-group',
      name: _TL_('Labels - Ad group'),
      searchAlias: _TL_('Labels - Ad group report'),
      route: 'customreports/create/labelsadgroupreport',
      scope: accountLevelScopes,
      apiKey: 'LabelAdGroupReport',
      description: _TL_('See performance data based on ad group labels and see if there are any patterns of performance based on labels.'),
    },
    {
      id: 'customreports-report-labels-campaign',
      name: _TL_('Labels - Campaign'),
      searchAlias: _TL_('Labels - Campaign report'),
      route: 'customreports/create/labelscampaignreport',
      scope: accountLevelScopes,
      apiKey: 'LabelCampaignReport',
      description: _TL_('See performance data based on campaign labels and see if there are any patterns of performance based on labels.'),
    },
    {
      id: 'report-v4-labels-account',
      name: _TL_('Labels - Account'),
      searchAlias: _TL_('Labels - Account report'),
      route: 'customreports/create/labelsaccountreport',
      scope: [Scope.Customer],
      apiKey: 'LabelAccountReport',
      description: _TL_('See performance data based on account labels and see if there are any patterns of performance based on labels.'),
    },
  ],
};
